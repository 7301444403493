import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./HeaderPreview.module.css"; // Ensure you create this CSS module
import { headings } from "./Headings";
function HeaderPreview({ html }) {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (html) {
      let temp = document.createElement("div");
      temp.innerHTML = html;
      let headerElements = temp.querySelectorAll("div[heading]");
      let result = Array.from(headerElements)
        .map((item) => ({
          title: item.getAttribute("heading"),
          text: item.innerText,
          id: item.id,
        }))
        .filter((s) => s.title != "div");

      setHeaders(result);
    } else {
      setHeaders([]);
    }
  }, [html]);

  return (
    <div className={classes.headerPreview}>
      {headers.map((header) => (
        <div
          key={header.id}
          className={classes.headerItem}
          style={{
            width: headings.filter((s) => s.value == header.title)[0].width,
          }}
        >
          {/* <div className={classes.headerTitle}>{header.text}</div> */}
        </div>
      ))}
    </div>
  );
}

HeaderPreview.propTypes = {
  html: PropTypes.string.isRequired,
};

export default HeaderPreview;
