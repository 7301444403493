export const createPlagiarismSuggestionResult = (suggestionValueList) => {
  let suggestionValueSimPositive = [];
  if (suggestionValueList != null) {
    for (let suggestionValue of suggestionValueList) {
      for (let i = 0; i < suggestionValue.length; i++) {
        if (
          suggestionValue[i].tfidf_sim != null &&
          suggestionValue[i].tfidf_sim != undefined &&
          suggestionValue[i].tfidf_sim > 0
        ) {
          suggestionValueSimPositive.push(suggestionValue[i]);
        }
      }
    }
  }
  suggestionValueSimPositive.sort(function (a, b) {
    return a.tfidf_sim - b.tfidf_sim;
  });
  suggestionValueSimPositive.reverse();
  return suggestionValueSimPositive;
};

export const getPlagiarismSuggestionColor = (value) => {
  let result = "";
  if (value >= 0.5) result = "#ff0000d1";
  else if (value < 0.5 && value >= 0.3) result = "#fd8e1e";
  else if (value < 0.3 && value >= 0.2) result = "#f5ff07c2";
  else if (value < 0.2 && value >= 0.1) result = "#00d20054";
  else result = "#00d200ba";
  return result;
};
