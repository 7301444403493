import React from "react";
import PropTypes from "prop-types";
import "./LoadPaperIcon.css";

export default function LoadPaperIcon(props) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={props.width}
        fill={"transparent"}
        style={{ marginRight: props.marginRight, paddingTop: props.paddingTop }}
        viewBox="0 0 500 500"
      >
        <g class="cogerAnimation">
          <path
            class="stShape"
            d="
		M332.8,27.2l-6.1,65.5l40.2,27.7c19.3-10.8,38.5-21.5,57.7-32.3l41,61.3l-51.3,42.3l5.2,42.5l64,28.1l-11.6,65.1l-66.8-2.5
		l-26.5,38.1l30,60.7l-58.3,41.6l-44.1-50.1l-42.8,6.7l-23.8,62.5l-68.3-9.8l1-67.5l-37.2-27.1l-61.3,29.1l-40.3-60.4l49.9-41.4
		l-10-49.9l-57.1-19.4l7.6-69.2l66.5,1.5l30.1-32.5L90.3,77.6c18.9-14,37.9-27.9,56.9-41.8l44.8,47c16.9-2.2,33.8-4.4,50.8-6.6
		L263,12L332.8,27.2z"
          />

          <path
            class="stLine"
            d="
		M347.5,183.8c6.8,10.1,19.3,31.6,21.3,61.4c2.9,42.8-17.9,73.6-24.6,82.7"
          />

          <path
            class="stLine"
            d="
		M155,179.8c-7,8.7-22.7,30.8-26.1,63.3c-4.1,39.7,13.1,68.9,18.7,77.8"
          />

          <path
            class="stLine"
            d="
		M198.1,361.4c28.9,16.1,63.8,17.5,93.9,3.8"
          />
        </g>
        <g>
          <path
            style={{ fill: "white" }}
            class="stPath"
            d="
		M320.2,184.1v135.1c0,12.1-10.1,22-21.4,23H200c-13.6-1-23.7-10.8-23.7-23V155.8c0-12.1,10.1-22,22.5-22h72.7l44.7,46.2
		L320.2,184.1z"
          />

          <path
            class="stPath"
            d="
		M323,186.1l-6.7-6.1l-44.7-46.2"
          />

          <path
            class="stPath"
            d="
		M318.8,183.4c-5.4,1.5-20.3-0.3-28.4-0.3c-5.2,0-12.8-3.8-16.8-8.7c-6.2-7.5-3.8-28.4-0.6-38.3c0-0.1,4.3,4.1,4.4,4"
          />
          <path
            class="stPath animation1"
            d="
		M201,182.3c0,0,34.2,0,44.8,0"
          />

          <path
            class="stPath animation2"
            d="
		M201,215c0,0,63.5,0,87.1,0"
          />

          <path
            class="stPath animation3"
            d="
		M201.6,246.1c0,0,67.9,0,89,0"
          />

          <path
            class="stPath animation4"
            d="
		M201,278.5c0,0,63.5,0,87.1,0"
          />

          <path
            class="stPath animation5"
            d="
		M200.4,310.1c0,0,68.8,0,90.1,0"
          />
        </g>
      </svg>
    </>
  );
}
LoadPaperIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  paddingTop: PropTypes.string,
  marginRight: PropTypes.string,
};
LoadPaperIcon.defaultProps = {
  width: "15px",
  marginRight: "",
  paddingTop: "",
};
