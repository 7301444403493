export const keywords = [
  {
    label: "\\documentclass",
    type: "keyword",
    apply: "\\documentclass{}",
    detail: "\\documentclass{article}",
  },
  {
    label: "\\usepackage",
    type: "keyword",
    apply: "\\usepackage{}",
    detail: "\\usepackage{amsmath}",
  },
  {
    label: "\\title",
    type: "keyword",
    apply: "\\title{}",
    detail: "\\title{Document Title}",
  },
  {
    label: "\\author",
    type: "keyword",
    apply: "\\author{}",
    detail: "\\author{Author Name}",
  },
  {
    label: "\\date",
    type: "keyword",
    apply: "\\date{}",
    detail: "\\date{\\today}",
  },
  {
    label: "\\maketitle",
    type: "keyword",
    apply: "\\maketitle",
    detail: "Generates title, author, and date",
  },
  {
    label: "\\begin",
    type: "keyword",
    apply: "\\begin{}",
    detail: "\\begin{document}",
  },
  {
    label: "\\end",
    type: "keyword",
    apply: "\\end{}",
    detail: "\\end{document}",
  },
  {
    label: "\\section",
    type: "keyword",
    apply: "\\section{}",
    detail: "\\section{Section Title}",
  },
  {
    label: "\\subsection",
    type: "keyword",
    apply: "\\subsection{}",
    detail: "\\subsection{Subsection Title}",
  },
  {
    label: "\\subsubsection",
    type: "keyword",
    apply: "\\subsubsection{}",
    detail: "\\subsubsection{Subsubsection Title}",
  },
  {
    label: "\\paragraph",
    type: "keyword",
    apply: "\\paragraph{}",
    detail: "\\paragraph{Paragraph Title}",
  },
  {
    label: "\\subparagraph",
    type: "keyword",
    apply: "\\subparagraph{}",
    detail: "\\subparagraph{Subparagraph Title}",
  },
  {
    label: "\\tableofcontents",
    type: "keyword",
    apply: "\\tableofcontents",
    detail: "Generates the table of contents",
  },
  {
    label: "\\abstract",
    type: "keyword",
    apply: "\\abstract{}",
    detail: "Abstract section in document class article",
  },
  {
    label: "\\chapter",
    type: "keyword",
    apply: "\\chapter{}",
    detail: "\\chapter{Chapter Title}",
  },
  {
    label: "\\appendix",
    type: "keyword",
    apply: "\\appendix",
    detail: "Begins the appendix section",
  },
  {
    label: "\\part",
    type: "keyword",
    apply: "\\part{}",
    detail: "\\part{Part Title}",
  },
  {
    label: "\\footnote",
    type: "keyword",
    apply: "\\footnote{}",
    detail: "\\footnote{Footnote Text}",
  },
  {
    label: "\\label",
    type: "keyword",
    apply: "\\label{}",
    detail: "\\label{sec:label}",
  },
  {
    label: "\\ref",
    type: "keyword",
    apply: "\\ref{}",
    detail: "\\ref{sec:label}",
  },
  {
    label: "\\pageref",
    type: "keyword",
    apply: "\\pageref{}",
    detail: "\\pageref{sec:label}",
  },
  {
    label: "\\include",
    type: "keyword",
    apply: "\\include{}",
    detail: "\\include{filename}",
  },
  {
    label: "\\includeonly",
    type: "keyword",
    apply: "\\includeonly{}",
    detail: "\\includeonly{filename}",
  },
  {
    label: "\\input",
    type: "keyword",
    apply: "\\input{}",
    detail: "\\input{filename}",
  },
  {
    label: "\\bibliography",
    type: "keyword",
    apply: "\\bibliography{}",
    detail: "\\bibliography{references}",
  },
  {
    label: "\\bibliographystyle",
    type: "keyword",
    apply: "\\bibliographystyle{}",
    detail: "\\bibliographystyle{plain}",
  },
  {
    label: "\\cite",
    type: "keyword",
    apply: "\\cite{}",
    detail: "\\cite{reference}",
  },
  {
    label: "\\begin{itemize}",
    type: "keyword",
    apply: "\\begin{itemize} ... \\end{itemize}",
    detail: "Creates a bullet list",
  },
  {
    label: "\\begin{enumerate}",
    type: "keyword",
    apply: "\\begin{enumerate} ... \\end{enumerate}",
    detail: "Creates a numbered list",
  },
  {
    label: "\\begin{description}",
    type: "keyword",
    apply: "\\begin{description} ... \\end{description}",
    detail: "Creates a description list",
  },
  {
    label: "\\item",
    type: "keyword",
    apply: "\\item",
    detail: "An item within a list",
  },
  {
    label: "\\begin{table}",
    type: "keyword",
    apply: "\\begin{table} ... \\end{table}",
    detail: "Creates a floating table",
  },
  {
    label: "\\begin{figure}",
    type: "keyword",
    apply: "\\begin{figure} ... \\end{figure}",
    detail: "Creates a floating figure",
  },
  {
    label: "\\caption",
    type: "keyword",
    apply: "\\caption{}",
    detail: "\\caption{Caption text}",
  },
  {
    label: "\\textbf",
    type: "keyword",
    apply: "\\textbf{}",
    detail: "\\textbf{Bold text}",
  },
  {
    label: "\\textit",
    type: "keyword",
    apply: "\\textit{}",
    detail: "\\textit{Italic text}",
  },
  {
    label: "\\underline",
    type: "keyword",
    apply: "\\underline{}",
    detail: "\\underline{Underlined text}",
  },
  {
    label: "\\emph",
    type: "keyword",
    apply: "\\emph{}",
    detail: "\\emph{Emphasized text}",
  },
  {
    label: "\\newcommand",
    type: "keyword",
    apply: "\\newcommand{}",
    detail: "\\newcommand{\\command}{definition}",
  },
  {
    label: "\\renewcommand",
    type: "keyword",
    apply: "\\renewcommand{}",
    detail: "\\renewcommand{\\command}{new definition}",
  },
  {
    label: "\\vspace",
    type: "keyword",
    apply: "\\vspace{}",
    detail: "\\vspace{1cm} adds vertical space",
  },
  {
    label: "\\hspace",
    type: "keyword",
    apply: "\\hspace{}",
    detail: "\\hspace{1cm} adds horizontal space",
  },
  {
    label: "\\clearpage",
    type: "keyword",
    apply: "\\clearpage",
    detail: "Forces a page break",
  },
  {
    label: "\\newline",
    type: "keyword",
    apply: "\\newline",
    detail: "Inserts a line break",
  },
  {
    label: "\\hline",
    type: "keyword",
    apply: "\\hline",
    detail: "Draws a horizontal line in tables",
  },
  {
    label: "\\rule",
    type: "keyword",
    apply: "\\rule{}{}",
    detail: "\\rule{width}{height} creates a line or box",
  },
  {
    label: "\\textheight",
    type: "keyword",
    apply: "\\textheight",
    detail: "The height of the text area",
  },
  {
    label: "\\textwidth",
    type: "keyword",
    apply: "\\textwidth",
    detail: "The width of the text area",
  },
  {
    label: "\\setlength",
    type: "keyword",
    apply: "\\setlength{}{}",
    detail: "\\setlength{\\parindent}{0pt} sets paragraph indentation",
  },
  {
    label: "\\addtolength",
    type: "keyword",
    apply: "\\addtolength{}{}",
    detail: "\\addtolength{\\textwidth}{2cm}",
  },
  {
    label: "\\fbox",
    type: "keyword",
    apply: "\\fbox{}",
    detail: "\\fbox{Boxed text}",
  },
];
