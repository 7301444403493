import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../../Loader/skeleton.css";
import "../../../Loader/Typing.css";
import "../../../LiboBerryCss/liboBerry.css";
import { getAutoComplete } from "../../../../Requests/Requests";

function AiTyping(props) {
  const [Result, setResult] = useState(props.result);
  const txtelement = useRef(null);
  var intervalId = useState(null);
  const formatLatexWithBr = (value) => {
    // Replace newline characters with <br/> for any explicit new lines
    let txt = value.replace(/\\n|\\r|\r|\n|\\\\/g, "<br/>");

    // List of LaTeX keywords to add <br/> after
    const keywords = [
      "\\section",
      "\\subsection",
      "\\subsubsection",
      "\\keywords",
      "\\author",
      "\\abstract",
      "\\begin",
      "\\title",
      "\\end",
    ];

    // Loop over each keyword and add <br/> after it
    for (const keyword of keywords) {
      const regex = new RegExp(`(\\${keyword}\\{[^}]*\\})`, "g"); // Match the keyword with content in {}

      txt = txt.replace(regex, `<br/>$1<br/>`); // Add <br/> after the keyword and content
    }

    // Return the formatted text to be displayed in HTML
    return txt;
  };
  const typingTxt = (i, txt, speed, wordcount = 1, formattextBr = false) => {
    if (txt && i < txt?.split(" ")?.length) {
      intervalId = setTimeout(() => {
        try {
          txt =
            props.editor == "CE" && formattextBr ? formatLatexWithBr(txt) : txt;
          let doc = txtelement.current;
          if (doc != null && doc.innerHTML != txt) {
            if (speed == 0) {
              doc.innerHTML = txt;
              clearTimeout(intervalId);
              intervalId = null;
            } else {
              let list = txt.split(" ");
              let newtxt = "";
              let countword = wordcount + i;
              countword = countword > list.length ? list.length : countword;
              for (; i < countword; i++) {
                if (i != 0) {
                  newtxt += list[i] == undefined ? "" : " " + list[i];
                } else {
                  newtxt += list[i] == undefined ? "" : list[i];
                }
              }
              doc.innerHTML += newtxt;
              doc.scrollIntoView({ behavior: "smooth", block: "end" });

              if (i >= txt.split(" ").length) {
                typingTxt(0, txt, 0, wordcount);
              } else {
                typingTxt(i, txt, speed, wordcount);
              }
            }
          } else {
            doc.innerHTML = txt;
            clearTimeout(intervalId);
            intervalId = null;
          }
        } catch {}
      }, speed);
    }
  };
  const callAutoComplete = (regenerate = false) => {
    let prameters = {
      paragraph: props.content,
      task: props.type,
    };
    if (props.Tone != null && props.type == "change_tone") {
      prameters = {
        paragraph: props.content,
        task: props.type,
        tone: props.Tone,
      };
    }
    if (props.type == "prompt") {
      prameters.prompt = props.askValue;
    }
    if (regenerate) {
      prameters.regenerate = regenerate;
      prameters.regentext = Result;
    }
    prameters.env = props.editor;

    getAutoComplete(props.ProjectId, prameters)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.completed_paragraph != null) {
            let txt = response.data.completed_paragraph;
            setResult(txt);
            props.onResult(txt);
            props.onStatus("success");
            typingTxt(0, txt, 100, 5, true);
          } else {
            let txt = response.data.error?.trim();

            txt =
              txt == null || txt == undefined || txt.trim() == ""
                ? "Ops! Failed to " + props.Caption + "!"
                : txt;
            setResult(txt);
            props.onResult(txt);
            props.onStatus("error");
          }
        }
      })
      .catch((error) => {
        // let txt =
        //   '<div heading="subsection" ><span style="font-weight: 700; font-family: Georgia;">IoT Architecture&nbsp;</span><br/></div>' +
        //   "<div ><ol><li>" +
        //   Math.random(10000000) +
        //   "</li><li><br/></li><li>" +
        //   Math.random(10000000) +
        //   "</li><li>" +
        //   Math.random(10000000) +
        //   "</li></ol></div>" +
        //   "<div >802.11 V2X has attracted much attention from industry and academia aiming to bring the success of WiFi to vehicle networks. The road</div>";
        // props.onResult(txt);
        // props.onStatus("success");
        // typingTxt(0, txt, 100, 5, true);

        console.error("Failed to " + props.Caption + " :", error.response);
        let txt = "Ops! Failed to " + props.Caption + "!";
        props.onResult(txt);
        setResult(txt);
        props.onStatus("error");
      });
  };
  useEffect(() => {
    if (props.status == null) {
      props.onStatus("loading");
      callAutoComplete();
    } else if (props.status == "regenerate") {
      props.onStatus("loading");
      callAutoComplete(true);
    } else if (props.status == "retry") {
      props.onStatus("loading");
      callAutoComplete();
    }
  }, [props.status, props.content]);
  useEffect(() => {
    if (props.status == "success" && Result != null && Result != "") {
      typingTxt(0, Result, 0, 5, true);
    } else if (props.status == "success" && Result == null && Result == "") {
      props.onStatus("loading");
      callAutoComplete();
    }
    return () => {
      clearTimeout(intervalId);
    };
  }, []);
  return (
    <>
      {props.status != null && props.status == "success" && (
        <div
          className={
            (props.editor == "CE" ? "CodeFont  " : "") +
            (props.type == "prompt" && props.editor == "CE"
              ? "Max-Content"
              : "")
          }
          style={{
            paddingBottom: "30px",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
          ref={txtelement}
        ></div>
      )}
      {props.status != null && props.status == "error" && (
        <div
          style={{
            backgroundColor: "tomato",
            color: "white",
            textAlign: "center",
            padding: "6px",
            borderRadius: "5px",
          }}
        >
          {Result == null || Result == ""
            ? "Ops! Failed to " + props.Caption + "!"
            : Result}
        </div>
      )}
      {(props.status == null || props.status == "loading") && (
        <>
          {props.loadingType == "typing" ? (
            <>
              <div className="Typingloader">
                <span className="Typingdot"></span>
                <span className="Typingdot"></span>
                <span className="Typingdot"></span>
              </div>
            </>
          ) : (
            <>
              <div className={"skeletonBox"} style={{ width: "100%" }}>
                {" "}
                &nbsp;
              </div>
              <div className={"skeletonBox"} style={{ width: "100%" }}>
                {" "}
                &nbsp;
              </div>
              <div className={"skeletonBox"} style={{ width: "100%" }}>
                {" "}
                &nbsp;
              </div>
              <div className={"skeletonBox"} style={{ width: "100%" }}>
                {" "}
                &nbsp;
              </div>
              <div className={"skeletonBox"} style={{ width: "100%" }}>
                {" "}
                &nbsp;
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
export default AiTyping;
AiTyping.propTypes = {
  status: PropTypes.string,
  content: PropTypes.string,
  onStatus: PropTypes.func,
  onResult: PropTypes.func,
  ProjectId: PropTypes.number,
  Caption: PropTypes.string,
  type: PropTypes.string,
  result: PropTypes.object,
  Tone: PropTypes.string,
  askValue: PropTypes.string,
  editor: PropTypes.string,
  loadingType: PropTypes.string,
};
AiTyping.defaultProps = {
  status: null,
  content: "",
  onStatus: () => {},
  onResult: () => {},
  ProjectId: 0,
  Caption: "Suggested",
  type: "complete",
  result: null,
  Tone: null,
  askValue: null,
  editor: "VE",
  loadingType: "skeleton",
};
