import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AIIcon from "../Icons/AIIcon";
import "../LiboBerryCss/liboBerry.css";
import "./LiboberryAIAssistents.css";
import AIItem from "./component/AIItem";
import { AiTones } from "../MSKEditor/AiTones";
import AiTyping from "../MSKEditor/Components/AISuggestion/AiTyping";
import PopUpIconButton from "../../components/PopUpIconButton/PopUpIconButton.js";

import CallSplitIcon from "@material-ui/icons/CallSplit";
import RefreshIcon from "@material-ui/icons/Refresh";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NotesIcon from "@material-ui/icons/Notes";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ReorderIcon from "@material-ui/icons/Reorder";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import BarChartIcon from "@material-ui/icons/BarChart";
import EnterIcon from "../Icons/EnterIcon.js";
import BookMarkBox from "../../Pages/DocumentPage/component/BookMarkBox.js";
import { TextField } from "@material-ui/core";
import {
  getPrompts,
  deletePrompts,
  getPromptsTags,
} from "../../Requests/Requests.js";
import AIAssistentPopUp from "./component/AIAssistentPopUp.js";
import { toast } from "react-toastify";
const LiboberryAIAssistents = (props) => {
  const [showBookMarkBox, setShowBookMarkBox] = useState(false);
  const [bookMarkItem, setBookMarkItem] = useState(null);
  const [loaderAiSuggestion, setLoaderAiSuggestion] = useState(false);
  const [aiCaption, setAiCaption] = useState(false);
  const [aiTitle, setAiTitle] = useState(false);
  const [aiLoading, setAiLoading] = useState(null);
  const [aiTone, setAiTone] = useState(null);
  const [aiCustomTone, setAiCustomTone] = useState(null);
  const [aiType, setAiType] = useState(null);
  const [showAiTone, setShowAiTone] = useState(false);
  const [showAsk, setShowAsk] = useState(false);
  const [clearAskAi, setClearAskAi] = useState(false);
  const [askAiSelectionStatus, setaskAiSelectionStatus] = useState("Document");
  const [prompts, setPrompts] = useState([]);
  const [promptLabels, setPromptLabels] = useState([]);
  useEffect(() => {
    callApiGetPrompts();
    callApiGetPrompts_tags();
  }, []);
  useEffect(() => {
    setLoaderAiSuggestion(false);
    setShowAiTone(false);
    if (!props.showAiSuggestion) {
      props.setShowPanelAi(false);
    }
  }, [props.showAiSuggestion]);

  const IntializeAiAssistent = () => {
    props.handleCloseShowAiSuggestion(false);
    if (props.IntializeAiAssistentRemoveAiPanel) {
      RemoveAiPanel();
    }
    props.getContent();
    setShowAsk(true);
    setClearAskAi(true);
    setAiTitle("Libo AI");

    setaskAiSelectionStatus(props.SelectionStatus);
  };
  useEffect(() => {
    if (props.showPanelAi) {
      setLoaderAiSuggestion(false);
      setShowAiTone(false);
    } else {
      setShowAiTone(false);
    }
  }, [props.showPanelAi]);

  useEffect(() => {
    if (!props.aiOpenPanel) {
      props.setAiOpenPanel(false);
      // setShowAsk(false);
    }
  }, [props.aiOpenPanel]);
  useEffect(() => {
    if (props.closeAiAskOnAiLiboberryAiAssistent) {
      props.setCloseAiAskOnAiLiboberryAiAssistent(false);
      setShowAsk(false);
    }
  }, [props.closeAiAskOnAiLiboberryAiAssistent]);

  useEffect(() => {
    if (props.showAiAsk) {
      props.setShowAiAsk(false);
      // if (props.AiAssistentClick()) {
      IntializeAiAssistent();
      // }
    }
  }, [props.showAiAsk]);
  const RemoveAiPanel = () => {
    // setAiTitle("");
    setAiCaption("");
    setAiLoading(null);
    props.setAiOpenPanel(false);
    props.setAiResult(null);
    setAiTone(null);
    setAiCustomTone(null);
    // setAiType(null);
    setShowAsk(false);
  };
  const callApiGetPrompts = () => {
    getPrompts()
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          setPrompts(res.data);
        } else {
          setPrompts([]);
        }
      })
      .catch((err) => {
        console.log(
          "Seems like there is a problem with the server ",
          err.message
        );
      });
  };

  const callApiRemovePrompts = (id) => {
    deletePrompts(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          props.removeBookMark(id);
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("Prompt has been deleted successfully");
          }
          setPrompts([...prompts.filter((s) => s.id != id)]);
        }
      })
      .catch((err) => {
        if (err.response.data.detail) {
          toast.warn(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.warn(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const callApiGetPrompts_tags = () => {
    getPromptsTags()
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          setPromptLabels(res.data);
        } else {
          setPromptLabels([]);
        }
      })
      .catch((err) => {
        console.log(
          "Seems like there is a problem with the server ",
          err.message
        );
      });
  };
  return (
    <>
      <BookMarkBox
        promptLabels={promptLabels}
        setPromptLabels={setPromptLabels}
        promptId={bookMarkItem?.bookmarkId}
        promptText={bookMarkItem?.text}
        promptTitle={bookMarkItem?.bookmarkTitle}
        PromptTags={bookMarkItem?.bookmarkTags}
        isShow={showBookMarkBox}
        Close={() => {
          setShowBookMarkBox(false);
        }}
        Save={(data) => {
          setShowBookMarkBox(false);
          callApiGetPrompts();
          let newBookMarkItem = bookMarkItem;
          newBookMarkItem.bookmarkId = data.bookmarkId;
          newBookMarkItem.bookmarkTitle = data.bookmarkTitle;
          newBookMarkItem.bookmarkTags = data.bookmarkTags;
          props.AddBookMark(newBookMarkItem);
        }}
      />
      {props.showAiSuggestion && (
        <>
          <div
            onClick={() => {
              if (!props.showPanelAi) {
                props.handleOpenPanelClick();
                props.setShowPanelAi((showPanelAi) => true);
              }
            }}
            style={{
              left: props.leftPosition,
              top: props.topPosition,
              position: props.position,
              bottom: props.bottomPosition,
              zIndex: 1,
            }}
            title={"LiboBerry AI Assistant"}
            className={
              "aIPanelSuggestion" +
              (props.showPanelAi && !loaderAiSuggestion ? "_open" : "")
            }
          >
            {(!props.showPanelAi || loaderAiSuggestion) && (
              <div
                className={
                  "aISuggestion" + (loaderAiSuggestion ? " aILoader" : "")
                }
              >
                <AIIcon color={"#fbaf46"} />
              </div>
            )}

            {props.showPanelAi && !loaderAiSuggestion && (
              <div style={{ width: "180px" }}>
                <div onClick={() => {}} className={"aiPanelSuggestionHeader"}>
                  <div className={"aITextItem"}>
                    <AIIcon color={"#fbaf46"} marginRight={"5px"} /> LiboBerry
                  </div>
                </div>

                <div className={"aISuggestion_BoxItem"}>
                  <AIItem
                    onClick={() => {
                      if (props.AiAssistentClick()) {
                        IntializeAiAssistent();
                      }
                    }}
                    title={
                      <>
                        <AIIcon color={"#fbaf46"} style={{ width: "0.8em" }} />
                        &nbsp;&nbsp; Ask Libo AI
                      </>
                    }
                  />
                  <AIItem
                    disabled={props.disabledAiAutoComplete}
                    onClick={() => {
                      if (!props.disabledAiAutoComplete) {
                        if (props.ContinueWritingClick()) {
                          RemoveAiPanel();
                          props.setShowPanelAi(false);
                          setLoaderAiSuggestion(true);
                        }
                      }
                    }}
                    title={
                      <>
                        <BorderColorIcon
                          style={{ width: "0.7em", color: "#7b6bc9" }}
                        />
                        &nbsp; Continue writing...
                      </>
                    }
                  />
                  <AIItem
                    onClick={() => {
                      if (props.CitationClick()) {
                        RemoveAiPanel();
                        props.setShowPanelAi(false);
                        // setLoaderAiSuggestion(true);
                      }
                    }}
                    title={
                      <>
                        <CallSplitIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Insert citation
                      </>
                    }
                  />
                  <AIItem
                    onClick={() => {
                      if (props.AiParaphraseClick()) {
                        RemoveAiPanel();
                        setAiTitle("AI Paraphrase");
                        setAiCaption("Paraphrased");
                        setAiType("paraphrase");
                        props.setAiOpenPanel(true);
                      }
                    }}
                    title={
                      <>
                        <FindReplaceIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Paraphrase
                      </>
                    }
                  />
                  <AIItem
                    onClick={() => {
                      if (props.AiMakeLongerClick()) {
                        RemoveAiPanel();
                        setAiTitle("AI Make longer");
                        setAiCaption("Make longer");
                        setAiType("extend");
                        props.setAiOpenPanel(true);
                      }
                    }}
                    title={
                      <>
                        <ReorderIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Make longer
                      </>
                    }
                  />
                  <AIItem
                    onClick={() => {
                      if (props.AiMakeSummarizeClick()) {
                        RemoveAiPanel();
                        setAiTitle("AI Summarize");
                        setAiCaption("Summarized");
                        setAiType("summarize");
                        props.setAiOpenPanel(true);
                      }
                    }}
                    title={
                      <>
                        <NotesIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Summarize
                      </>
                    }
                  />
                  <AIItem
                    onClick={() => {
                      if (props.AiMakeShorterClick()) {
                        RemoveAiPanel();
                        setAiTitle("AI Make shorter");
                        setAiCaption("Make shorter");
                        setAiType("shorten");
                        props.setAiOpenPanel(true);
                      }
                    }}
                    title={
                      <>
                        <DragHandleIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Make shorter
                      </>
                    }
                  />
                  <div
                    className={"aISuggestion_MenuItem"}
                    style={{ borderBottom: "0px none transparent" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      onClick={() => {
                        if (props.AiToneClick()) {
                          setShowAiTone(!showAiTone);
                        }
                      }}
                    >
                      <div className={"aITextItem"}>
                        <BarChartIcon
                          style={{ width: "0.8em", color: "#7b6bc9" }}
                        />
                        &nbsp; Change tone
                      </div>
                      <div className={"aiSuggestionArrow_Icon"}>
                        <ArrowRightIcon />
                      </div>
                    </div>
                    <>
                      <div
                        className={"aISuggestion_BoxItem aiSubMenuOpen"}
                        style={{
                          display: showAiTone ? "block" : "none",
                          width: "170px",
                          left: props.showsubMenuRight ? "170px" : "-160px",
                        }}
                      >
                        <div>
                          {AiTones.map((tone, index) => {
                            return (
                              <AIItem
                                key={index}
                                style={{ fontSize: "1em" }}
                                tooltip={tone.label + " Tone"}
                                onClick={() => {
                                  if (props.ToneSubMenuClick()) {
                                    RemoveAiPanel();
                                    setAiTitle(
                                      "AI Change tone : " + tone.label
                                    );
                                    setAiCaption("Change tone");
                                    setAiType("change_tone");
                                    setAiTone(tone.key);
                                    props.setAiOpenPanel(true);
                                  }
                                }}
                                title={<>{tone.label}</>}
                              />
                            );
                          })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            padding: "0px 5px 0px 5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            placeholder="Custom Tone"
                            // variant="outlined"
                            size="small"
                            disabled={false}
                            InputProps={{
                              className: "inputFont inputFontSize0_9em",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (props.ToneCunstomClick()) {
                                        if (
                                          aiCustomTone != null &&
                                          aiCustomTone.trim() != ""
                                        ) {
                                          let temp = aiCustomTone;
                                          RemoveAiPanel();
                                          setAiTitle(
                                            "AI Change Tone : " +
                                              temp.substring(0, 30) +
                                              (temp.length > 30 ? "..." : "")
                                          );
                                          setAiCaption("Change Tone");
                                          setAiType("change_tone");
                                          setAiTone(temp);
                                          props.setAiOpenPanel(true);
                                        }
                                      }
                                    }}
                                  >
                                    <EnterIcon title={"Enter"} />
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            defaultValue={aiCustomTone}
                            onKeyDown={(e) => {
                              if (
                                e.which == 13 &&
                                aiCustomTone != null &&
                                aiCustomTone.trim() != ""
                              ) {
                                if (props.ToneCunstomClick()) {
                                  e.stopPropagation();
                                  props.setPressEnterChange_tone(true);
                                  e.preventDefault();

                                  let temp = aiCustomTone;
                                  RemoveAiPanel();
                                  setAiTitle(
                                    "AI Change Tone : " +
                                      temp.substring(0, 30) +
                                      (temp.length > 30 ? "..." : "")
                                  );
                                  setAiCaption("Change Tone");
                                  setAiType("change_tone");
                                  setAiTone(temp);
                                  props.setAiOpenPanel(true);
                                }
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.which == 13) e.preventDefault();
                            }}
                            onChange={(e) => {
                              setAiCustomTone(e.target.value);
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {props.aiElement && showAsk && (
        <AIAssistentPopUp
          ClearHistory={props.ClearHistory}
          promptLabels={promptLabels}
          userInfo={props.userInfo}
          bookmarkList={prompts}
          removeBookMark={(value) => {
            callApiRemovePrompts(value);
          }}
          getContent={props.getContent}
          IntializeAiAssistentRemoveAiPanel={
            props.IntializeAiAssistentRemoveAiPanel
          }
          EditBookMark={(item) => {
            setBookMarkItem(item);
            setShowBookMarkBox(true);
          }}
          setAiContent={props.setAiContent}
          taggleBookMark={(item) => {
            if (item.bookmark) {
              callApiRemovePrompts(item.bookmarkid);
            } else {
              setBookMarkItem(item);
              setShowBookMarkBox(true);
            }
          }}
          liboAiHistory={props.liboAiHistory}
          AddLiboAiHistory={props.AddLiboAiHistory}
          ShowAllHistory={props.ShowAllHistory}
          editor={props.editor}
          clearAskAi={clearAskAi}
          setClearAskAi={setClearAskAi}
          Close={() => {
            props.AiDismissClick();
            RemoveAiPanel();
            setShowAsk(false);
          }}
          AiReplaceClick={props.AskAiApproveClick}
          SelectionStatus={askAiSelectionStatus}
          RemoveAiPanel={RemoveAiPanel}
          AiAssistentClick={(value) => {
            if (value == null || value.trim() == "") {
              return false;
            }
            return true;
          }}
          projectid={props.projectid}
          aiResult={props.aiResult}
          setAiResult={props.setAiResult}
          aiContent={props.aiContent}
          setShowAsk={setShowAsk}
          showAsk={showAsk}
        />
      )}
      {props.aiElement && props.aiOpenPanel && (
        <PopUpIconButton
          showPopup={props.aiOpenPanel}
          onSetShowPopup={props.setAiOpenPanel}
          showbtnPopup={false}
          customValue={
            <AiTyping
              editor={props.editor}
              Caption={aiCaption}
              status={aiLoading}
              content={props.aiContent}
              onStatus={setAiLoading}
              onResult={props.setAiResult}
              Tone={aiTone}
              result={props.aiResult}
              type={aiType}
              ProjectId={props.projectid}
            />
          }
          footerValue={
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: "20px",
              }}
            >
              {aiLoading == "success" &&
                props.aiResult != null &&
                props.aiResult != "" && (
                  <>
                    <div>
                      <input
                        type="button"
                        value={"Replace"}
                        className={"liboBtnApprove"}
                        onClick={() => {
                          if (props.AiReplaceClick()) {
                            RemoveAiPanel();
                          }
                        }}
                      ></input>
                    </div>
                  </>
                )}
              {((aiLoading == "success" &&
                props.aiResult != null &&
                props.aiResult != "") ||
                aiLoading == "error") && (
                <>
                  <div>
                    <a
                      className={"liboBtnRefreshWithIcon"}
                      onClick={() => {
                        setAiLoading(
                          aiLoading == "error" ? "retry" : "regenerate"
                        );
                      }}
                    >
                      <>
                        {aiLoading == "error" && (
                          <>
                            Try Again{" "}
                            <RefreshIcon
                              style={{ width: "0.8em" }}
                              className={"rotate360"}
                            />
                          </>
                        )}
                        {aiLoading == "success" && (
                          <>
                            Regenerate{" "}
                            <AutorenewIcon
                              style={{ width: "0.8em", marginLeft: "3px" }}
                              className={"rotate360"}
                            />
                          </>
                        )}
                        &nbsp;
                      </>
                    </a>
                  </div>
                </>
              )}
              {aiLoading != null && (
                <div>
                  <input
                    type="button"
                    value={"Dismiss"}
                    className={"liboBtnCancel"}
                    onClick={() => {
                      props.AiDismissClick();
                      RemoveAiPanel();
                    }}
                  ></input>
                </div>
              )}
            </div>
          }
          title={aiTitle}
          pBottom="55px"
          pRight="20px"
          bBottom="0px"
          bRight="0px"
          bWidth="25px"
        />
      )}
    </>
  );
};

export default LiboberryAIAssistents;

LiboberryAIAssistents.propTypes = {
  showAiSuggestion: PropTypes.bool,
  handleCloseShowAiSuggestion: PropTypes.func,
  aiOpenPanel: PropTypes.bool,
  setAiOpenPanel: PropTypes.func,
  handleOpenPanelClick: PropTypes.func,
  leftPosition: PropTypes.string,
  topPosition: PropTypes.string,
  position: PropTypes.string,
  CitationClick: PropTypes.func,
  aiResult: PropTypes.func,
  setAiResult: PropTypes.func,
  disabledAiAutoComplete: PropTypes.bool,
  ContinueWritingClick: PropTypes.func,
  showsubMenuRight: PropTypes.bool,
  AiParaphraseClick: PropTypes.func,
  AiMakeLongerClick: PropTypes.func,
  AiMakeSummarizeClick: PropTypes.func,
  AiMakeShorterClick: PropTypes.func,
  AiToneClick: PropTypes.func,
  ToneSubMenuClick: PropTypes.func,
  ToneCunstomClick: PropTypes.func,
  aiContent: PropTypes.string,
  setPressEnterChange_tone: PropTypes.func,
  AiReplaceClick: PropTypes.func,
  AiDismissClick: PropTypes.func,
  showPanelAi: PropTypes.bool,
  setShowPanelAi: PropTypes.func,
  SelectionStatus: PropTypes.func,
  AiAssistentClick: PropTypes.func,
  setCloseAiAskOnAiLiboberryAiAssistent: PropTypes.func,
  closeAiAskOnAiLiboberryAiAssistent: PropTypes.bool,
  getContent: PropTypes.func,
  setShowAiAsk: PropTypes.func,
  showAiAsk: PropTypes.bool,
  editor: PropTypes.string,
  liboAiHistory: PropTypes.array,
  AddLiboAiHistory: PropTypes.func,
  ShowAllHistory: PropTypes.func,
  aiElement: PropTypes.object,
  IntializeAiAssistentRemoveAiPanel: PropTypes.bool,
  AddBookMark: PropTypes.func,
  setAiContent: PropTypes.func,
  bookmarkList: PropTypes.array,
  userInfo: PropTypes.object,
  removeBookMark: PropTypes.func,
  AiInsertClick: PropTypes.func,
  AskAiApproveClick: PropTypes.func,
  ClearHistory: PropTypes.func,
};
LiboberryAIAssistents.defaultProps = {
  showAiSuggestion: false,
  handleCloseShowAiSuggestion: () => {},
  aiOpenPanel: false,
  setAiOpenPanel: () => {},
  handleOpenPanelClick: () => {},
  leftPosition: "",
  topPosition: "",
  position: "",
  CitationClick: () => {
    return false;
  },
  projectid: 0,
  aiResult: null,
  setAiResult: () => {},
  disabledAiAutoComplete: false,
  ContinueWritingClick: () => {
    return false;
  },
  AiParaphraseClick: () => {
    return false;
  },
  AiMakeLongerClick: () => {
    return false;
  },
  AiMakeSummarizeClick: () => {
    return false;
  },
  AiMakeShorterClick: () => {
    return false;
  },
  AiToneClick: () => {
    return true;
  },
  ToneSubMenuClick: () => {
    return false;
  },
  ToneCunstomClick: () => {
    return false;
  },
  AiReplaceClick: () => {
    return true;
  },
  AiAssistentClick: () => {
    return true;
  },
  AiDismissClick: () => {},
  aiContent: "",
  showsubMenuRight: true,
  setPressEnterChange_tone: () => {},
  showPanelAi: false,
  setShowPanelAi: () => {},
  bottomPosition: "",
  SelectionStatus: "Document",
  setCloseAiAskOnAiLiboberryAiAssistent: () => {},
  closeAiAskOnAiLiboberryAiAssistent: false,
  getContent: () => {},
  setShowAiAsk: () => {},
  showAiAsk: false,
  editor: "VE",
  liboAiHistory: [],
  AddLiboAiHistory: () => {},
  ShowAllHistory: () => {},
  aiElement: null,
  IntializeAiAssistentRemoveAiPanel: false,
  AddBookMark: () => {},
  setAiContent: () => {},
  bookmarkList: [],
  userInfo: null,
  removeBookMark: () => {},
  AiInsertClick: () => {},
  AskAiApproveClick: () => {},
  ClearHistory: () => {},
};
