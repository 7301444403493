import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Menu,
  Select,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import {
  handleShortCutKey,
  getShortCut,
} from "../../../Functions/editorShortcut";
import { toast } from "react-toastify";
import editTableIconSrc from "./Images/edittable.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ShareIcon from "@material-ui/icons/Share";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import rowRemoveTableSrc from "./Images/table-row-remove.svg";
import colInsertAfterTableSrc from "./Images/table-insert-column-after-svg.svg";
import rowInsertAfterTableSrc from "./Images/table-insert-row-after-svg.svg";
import colInsertBeforeTableSrc from "./Images/table-insert-column-before-svg.svg";
import rowInsertAboveTableSrc from "./Images/table-insert-row-above-svg.svg";
import removeColTableSrc from "./Images/table-col-remove-svg.svg";
import removeTableSrc from "./Images/table-remove.svg";
import { TableSizes } from "./TableSizes";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import FunctionsIcon from "@material-ui/icons/Functions";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import SaveIcon from "@material-ui/icons/Save";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import CodeIcon from "@material-ui/icons/Code";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import SettingsIcon from "@material-ui/icons/Settings";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import FormatIndentDecreaseIcon from "@material-ui/icons/FormatIndentDecrease";
import RemoveIcon from "@material-ui/icons/Remove";
import { headings } from "./Headings";
import { ExportType } from "../../../Pages/DocumentsPage/ExportType";
import GridOnIcon from "@material-ui/icons/GridOn";
import ButtonWithPopover from "./ButtonWithPopover";
import { createHTMLTable } from "./createHTMLTable";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classes from "./Toolbar.module.css";
import "../../LiboBerryCss/liboBerry.css";
import { ImageDocumentUploadFileAllowMimeType } from "../../../Functions/FilterUploadFile.js";
import FormulaDialog from "./FormulaDialog/FormulaDialog.js";

import HeaderPreview from "./HeaderPreview";
import classNames from "classnames";
import PropTypes from "prop-types";

const save = function () {
  const selection = window.getSelection();
  return selection.rangeCount === 0 ? null : selection.getRangeAt(0);
};

const restore = function (range) {
  try {
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } catch (ex) {}
};

let prevSelection = null;

function getSelectionProperty(selection, prop) {
  return window
    .getComputedStyle(selection.anchorNode.parentElement, null)
    .getPropertyValue(prop);
}

function getHeadingType(selection) {
  let selectionParent = selection.anchorNode;
  const headingType = "div";
  let loopSize = 0;
  while (selectionParent.parentElement) {
    let node = selectionParent.parentElement;
    while (
      node != null &&
      node.tagName != "ARTICLE" &&
      !(node.tagName == "DIV" && node.hasAttribute("heading")) &&
      !(node.tagName == "DIV" && node.parentNode?.tagName == "ARTICLE")
    ) {
      node = node.parentNode;
    }
    if (node != null && node.hasAttribute("heading")) {
      let nodeValue = node.getAttribute("heading");
      if (nodeValue != null && nodeValue != undefined && nodeValue != "") {
        return nodeValue;
      }
    }
    // if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'DIV'].includes(selectionParent.parentElement.tagName)) {
    //     return selectionParent.parentElement.tagName;
    // }

    // if (selectionParent.parentElement.tagName === 'article') return headingType;
    // selectionParent = selectionParent.parentElement;
    loopSize++;
    if (loopSize > 1000) return headingType;
  }
}

const activeColor = "blue";
const defaultColor = "rgba(0, 0, 0, 0.54)";

function Toolbar(props) {
  const { sel, selChanged } = props;
  const [exportElment, setExportElment] = React.useState(null);
  const open = Boolean(exportElment);

  const handleClickExport = (event) => {
    setExportElment(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportElment(null);
  };
  const onCommand = (cmd, val) => {
    if (cmd === "createLink") {
      // let url = prompt('Enter the link:', val);
      document.execCommand(cmd, false, val);
    }
    //  else if (cmd === "fontName") {
    //   document.execCommand(cmd, false, val);
    // }
    else if (cmd === "fontSize") {
      document.execCommand(cmd, false, val);
    } else if (cmd === "foreColor") {
      document.execCommand(cmd, false, val);
    } else if (cmd === "backColor") {
      document.execCommand(cmd, false, val);
    } else if (cmd === "formatBlock") {
      document.execCommand(cmd, false, val);
    } else if (cmd === "insertImage") {
      document.execCommand(cmd, false, val);
    } else {
      document.execCommand(cmd, false, null);
    }
  };

  const [fontFamily, setFontFamily] = React.useState("Cambria");
  const [fontSize, setFontSize] = React.useState(2);
  const [activeHeading, setActiveHeading] = React.useState("DIV");
  const [activeAlign, setActiveAlign] = useState("left");
  const [isBold, setIsBold] = useState(false);
  const [isTable, setIsTable] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isQuote, setIsQuote] = useState(false);
  const [isFormula, setIsFormula] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikeThrough, setIsStrikeThrough] = useState(false);
  const [link, setLink] = React.useState("");
  const [code, setCode] = React.useState("");
  const [isInsertFormulaModelOpen, setIssInsertFormulaModelOpen] =
    useState(false);

  const [latexFormulaValue, setLatexFormulaValue] = useState("");
  const [removeFormula, setRemoveFormula] = useState(false);
  const [latexLabelValue, setLatexLabelValue] = useState("");
  const [latexInline, setLatexInline] = useState(true);

  const [tableSize, setTableSize] = useState([-1, -1]);
  const [tableEditProperties, setTableEditProperties] = useState({
    caption: "",
    label: "",
    size: "",
  });
  const [isEditTableModelOpen, setIsEditTableModelOpen] = useState(false);
  const [columnElement, setColumnElement] = useState(null);
  const [columnIndexElement, setColumnIndexElement] = useState(-1);
  const [columnWidthElement, setColumnWidthElement] = useState(null);

  const {
    isOpenHeadersPanel,
    onClickHeadersPanel,
    isShowHeadersItem,
    htmlContent,
  } = props;
  const [elmentForImageToolbar, setElmentForImageToolbar] =
    React.useState(null);
  const isOpenElmentForImageToolbar = Boolean(elmentForImageToolbar);
  const handleHeaderPreviewClick = () => {
    onClickHeadersPanel(); // Toggle the headers panel visibility
  };
  const handleClickElmentForImageToolbar = (event) => {
    setElmentForImageToolbar(event.currentTarget);
  };
  const handleCloseElmentForImageToolbar = () => {
    setElmentForImageToolbar(null);
  };
  const insertImage = (id, url) => {
    if (props.onPositionImageValue == null) {
      toast.error("Specify the location of the Image");
      return false;
    }
    if (
      props.onPositionImageValue.startContainer.parentNode.tagName == "REF" ||
      props.onPositionImageValue.startContainer.parentNode.tagName ==
        "REFERENCES"
    ) {
      toast.error("Can not insert Image in to References");
      return false;
    }
    let divElment = props.onPositionImageValue.startContainer.parentNode;
    while (
      divElment != null &&
      divElment.tagName != "DIV" &&
      divElment.tagName == "LIBOMATH" &&
      divElment.tagName != "ARTICLE"
    ) {
      divElment = divElment.parentNode;
    }
    if (divElment.tagName == "LIBOMATH") {
      toast.error("can not insert image to formula");
      return false;
    }
    if (
      divElment != null &&
      divElment.tagName == "DIV" &&
      divElment.hasAttribute("caption")
    ) {
      toast.error("Can not insert Image in to Image Block");
      return false;
    }
    if (
      divElment != null &&
      divElment.tagName == "DIV" &&
      divElment.hasAttribute("tablecaption")
    ) {
      toast.error("Can not insert Image in to Table Block");
      return false;
    }
    try {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(props.onPositionImageValue);
    } catch (ex) {}

    document.execCommand(
      "insertHTML",
      false,
      '<div caption="true" style="text-align:center"><imgBlock><img scale="0.5" style="max-width:50%" loading="lazy" title="" src="' +
        url +
        '"/></imgBlock><imgFigure class="Figure_' +
        id +
        '">Figure-{number} </imgFigure><imgcaption>Image Caption</imgcaption> </div><div></br></div>'
    );
    toast.success("Image inserted!");
    props.onOrderByFigures();
    props.onPositionImage(null);
    return true;
  };
  const handleFileSelect = (e) => {
    let list = [...e.target.files].filter(
      (item) =>
        ImageDocumentUploadFileAllowMimeType(item.type) &&
        item.name.length < 100
    );
    let errorlist = [...e.target.files].filter(
      (item) =>
        !ImageDocumentUploadFileAllowMimeType(item.type) ||
        item.name.length >= 100
    );
    for (let error of errorlist) {
      if (!ImageDocumentUploadFileAllowMimeType(error.type)) {
        toast.error(
          "Upload failed! The file '" + error.name + "' does not support Format"
        );
      } else {
        toast.error(
          "Upload failed! The file '" +
            error.name +
            "' exceed than 100 characters. Please rename the file."
        );
      }
    }
    if (list && list.length > 0) {
      props.onFilesUploadButtonClick(list);
    }
  };
  const removeTableBlock = () => {
    if (columnElement && columnElement != null) {
      let td = columnElement;
      let tablediv = td;
      while (
        tablediv != null &&
        !(tablediv.tagName == "DIV" && tablediv.hasAttribute("tablecaption"))
      ) {
        tablediv = tablediv.parentNode;
      }
      if (tablediv == null) {
        handleCloseTableModel();
        return;
      }
      tablediv.remove();
      setIsTable(false);
      setColumnElement(null);
      setColumnIndexElement(-1);
      setColumnWidthElement(null);
    }
    handleCloseTableModel();
  };
  const handleClickOpenTableModel = () => {
    if (columnElement && columnElement != null) {
      let td = columnElement;
      let tablediv = td;
      while (
        tablediv != null &&
        !(tablediv.tagName == "DIV" && tablediv.hasAttribute("tablecaption"))
      ) {
        tablediv = tablediv.parentNode;
      }
      if (tablediv == null) {
        setIsEditTableModelOpen(false);
        return;
      }
      let table = tablediv.getElementsByTagName("table");
      if (table != null && table.length != 0) {
        table = table[0];
      } else {
        return;
      }
      let caption = tablediv.getElementsByTagName("tablecaption");
      if (caption.length > 0) {
        caption = caption[0];
        setTableEditProperties({
          caption: caption.innerText,
          label: table.getAttribute("title"),
          size: table.style.maxWidth,
        });
      } else {
        setTableEditProperties({
          caption: "",
          label: table.getAttribute("title"),
          size: table.style.maxWidth,
        });
      }
    }
    setIsEditTableModelOpen(true);
  };

  const handleCloseTableModel = () => {
    setIsEditTableModelOpen(false);
    setTableEditProperties({ caption: "", label: "", size: "" });
    setColumnElement(null);
    setColumnIndexElement(-1);
    setColumnWidthElement(null);
  };

  const handleClickOpenFormulaModel = () => {
    prevSelection = save();
    setRemoveFormula(false);
    if (props.onAllowToInsertFormula(prevSelection)) {
      let el = prevSelection.startContainer.parentNode;
      while (
        el != null &&
        el.tagName != "ARTICLE" &&
        el.tagName != "LIBOMATH" &&
        el.tagName != "DIV"
      ) {
        el = el.parentNode;
      }

      if (el != null && el.tagName == "LIBOMATH") {
        setLatexFormulaValue(
          el.hasAttribute("latex-data") ? el.getAttribute("latex-data") : ""
        );
        setLatexInline(el.hasAttribute("inline"));
        setLatexLabelValue(
          el.hasAttribute("title") ? el.getAttribute("title") : ""
        );
        setRemoveFormula(true);
      } else {
        setLatexFormulaValue("");
        setLatexInline(true);
        setLatexLabelValue("");
      }
      if (IsInArticle(prevSelection)) {
        setIssInsertFormulaModelOpen(true);
      }
    }
  };

  const InsertFormulaInEditor = (latexValue, label, inline, formula_div) => {
    restore(prevSelection);

    setTimeout(props.setLiboMathClickingInLiboEditor, 1000);

    if (formula_div[0]) {
      formula_div = formula_div[0];
      let spans = formula_div.getElementsByClassName(
        "mjx-char MJXc-TeX-size2-R"
      );
      for (let span of spans) {
        span.style.paddingTop = "0.3rem";
        span.style.paddingBottom = "0.3rem";
      }
      let el = prevSelection.startContainer.parentNode;
      while (
        el != null &&
        el.tagName != "ARTICLE" &&
        el.tagName != "LIBOMATH" &&
        el.tagName != "DIV"
      ) {
        el = el.parentNode;
      }
      if (el != null && el.tagName == "LIBOMATH") {
        if (latexValue.trim() == "") {
          el.remove();
          handleCloseFormulaModel();
          return;
        }

        el.className = "mjx-math";
        el.setAttribute("latex-data", latexValue.trim());
        if (inline) {
          el.setAttribute("inline", "true");
        } else {
          el.removeAttribute("inline");
        }
        el.setAttribute("title", label.trim());
        el.innerHTML =
          "<libomath_space>&nbsp;</libomath_space>" +
          formula_div.innerHTML.replace(/\s(id)="[^"]+"/g, "");
      } else {
        if (latexValue.trim() == "") {
          handleCloseFormulaModel();
          return;
        }

        let libomath = document.createElement("libomath");
        libomath.className = "mjx-math";
        libomath.setAttribute("latex-data", latexValue.trim());
        if (latexInline) {
          libomath.setAttribute("inline", "true");
        } else {
          libomath.removeAttribute("inline");
        }
        libomath.setAttribute("title", label.trim());
        libomath.innerHTML =
          "<libomath_space>&nbsp;</libomath_space>" +
          formula_div.innerHTML.replace(/\s(id)="[^"]+"/g, "");

        //

        let el = prevSelection.startContainer.parentNode;
        let nextEl = prevSelection.startContainer.nextSibling;
        let startPart = prevSelection.startContainer.textContent.substring(
          0,
          prevSelection.startOffset
        );
        let endPart = prevSelection.startContainer.textContent.substring(
          prevSelection.startOffset
        );
        let part1 = document.createTextNode(startPart);
        let part2 = document.createTextNode(endPart);

        if (el.tagName == "ARTICLE") {
          let div = document.createElement("DIV");
          div.appendChild(part1);
          div.appendChild(libomath);
          div.appendChild(part2);
          if (nextEl != null) {
            el.insertBefore(div, nextEl);
          } else {
            el.appendChild(div);
          }
        } else {
          if (nextEl != null) {
            el.insertBefore(part1, nextEl);
            el.insertBefore(libomath, nextEl);
            el.insertBefore(part2, nextEl);
          } else {
            el.appendChild(part1);
            el.appendChild(libomath);
            el.appendChild(part2);
          }
          part1.previousSibling.remove();
        }
      }
    }
  };
  const removeLatexFormule = () => {
    restore(prevSelection);
    let el = prevSelection.startContainer.parentNode;
    while (
      el != null &&
      el.tagName != "ARTICLE" &&
      el.tagName != "LIBOMATH" &&
      el.tagName != "DIV"
    ) {
      el = el.parentNode;
    }

    if (el != null && el.tagName == "LIBOMATH") {
      let nextEl = el.nextSibling;
      let prevEl = el.previousSibling;
      console.log(prevEl, nextEl);
      el.remove();

      if (prevEl && prevEl != null) {
        props.cursorPostion(prevEl, prevEl.length);
      } else if (nextEl && nextEl != null) {
        props.cursorPostion(nextEl, 1);
      }
    }
  };

  const handleCloseFormulaModel = () => {
    setIssInsertFormulaModelOpen(false);
    setRemoveFormula(false);
    setIsFormula(() => false);
  };
  const handleTableSizeChange = (i, j) => {
    setTableSize([i, j]);
  };
  const handleOnShortCutKey = (e) => {
    let shortcutAction = handleShortCutKey(e);

    if (shortcutAction == "save") {
      props.handleOnSaveClick();
      e.preventDefault();
    } else if (shortcutAction == "InsertFormula") {
      // Insert Formula
      handleClickOpenFormulaModel();
    } else if (
      shortcutAction == "bold" ||
      shortcutAction == "italic" ||
      shortcutAction == "underline" ||
      shortcutAction == "strikeThrough" ||
      shortcutAction == "subscript" ||
      shortcutAction == "superscript"
    ) {
      onCommand(shortcutAction);
      if (shortcutAction == "bold") setIsBold((isBold) => !isBold);
      else if (shortcutAction == "italic") setIsItalic((isItalic) => !isItalic);
      else if (shortcutAction == "underline")
        setIsUnderline((isUnderline) => !isUnderline);
      else if (shortcutAction == "strikeThrough")
        setIsStrikeThrough((isStrikeThrough) => !isStrikeThrough);

      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleOnShortCutKey);
    return () => {
      document.removeEventListener("keydown", handleOnShortCutKey);
    };
  }, []);
  useEffect(() => {
    props.onResizeEditor();
  }, [props.isOpenDropDownPanel]);
  useEffect(() => {
    if (props.openFormula && props.openFormula != null) {
      handleClickOpenFormulaModel();
    }
  }, [props.openFormula]);
  useEffect(() => {
    props.onResizeEditor();
  }, [isTable]);

  const IsInTag = (tag, selection) => {
    let node = selection.anchorNode.parentElement;
    while (node != null && node.tagName != tag.toUpperCase()) {
      node = node.parentNode;
    }
    return node != null && node.tagName == tag.toUpperCase();
  };
  const getTdIndexInTr = (td) => {
    let index = -1;
    if (td && td != null) {
      let node = td;
      while (node != null && node.tagName == "TD") {
        index++;
        node = node.previousElementSibling;
      }
    }
    return index;
  };
  function getIsTable(selection) {
    try {
      let node = selection.anchorNode;
      while (
        node != null &&
        node.tagName != "ARTICLE" &&
        node.tagName != "TD"
      ) {
        node = node.parentNode;
      }

      if (node != null && node.tagName == "TD") {
        setColumnElement(node);
        setColumnIndexElement(getTdIndexInTr(node));
        let width = node.style.width;
        if (width) {
          setColumnWidthElement(width.replace("%", ""));
        } else {
          setColumnWidthElement(width);
        }

        return true;
      }

      setColumnElement(null);
      setColumnIndexElement(-1);
      setColumnWidthElement(null);
      return false;
    } catch {
      setColumnElement(null);
      setColumnIndexElement(-1);
      setColumnWidthElement(null);
      return false;
    }
  }
  useEffect(() => {
    const selection = sel;
    if (selection) {
      // make sure it doesn't error if nothing is selected
      // console.log()
      if (selection.anchorNode) {
        // let fontFamily = getSelectionProperty(selection, "font-family");
        // let fontSize = getSelectionProperty(selection, 'font-size');
        let fontWeight = getSelectionProperty(selection, "font-weight");
        let fontStyle = getSelectionProperty(selection, "font-style");
        let textDecoration = getSelectionProperty(selection, "text-decoration");
        let textAlign = getSelectionProperty(selection, "text-align");

        // setFontFamily(
        //   fontFamily.replaceAll('"', "") == ""
        //     ? "Cambria"
        //     : fontFamily.replaceAll('"', "")
        // );
        setActiveAlign(textAlign);
        setIsBold(fontWeight === "700");
        setIsQuote(IsInTag("Q", selection));
        setIsFormula(IsInTag("LIBOMATH", selection));
        setIsCode(IsInTag("CODE", selection));
        setIsItalic(fontStyle === "italic");
        setIsUnderline(textDecoration.includes("underline"));
        setIsStrikeThrough(textDecoration.includes("line-through"));
        setActiveHeading(getHeadingType(selection));
        setIsTable(getIsTable(selection));
        // setFontSize(Number(fontSize.replace('px', '')));
      }
    }
  }, [selChanged]);

  // const handleChange = (event) => {
  //   setFontFamily(event.target.value);
  //   onCommand("fontName", event.target.value);
  // };

  // const handleChangeFontSize = (event) => {
  //     setFontSize(event.target.value);
  //     onCommand('fontSize', event.target.value)
  // };

  const handleHeadingChange = (value) => {
    // let selectionParent = sel?.anchorNode?.parentElement;
    // if (selectionParent && selectionParent.tagName === 'SPAN' && selectionParent.className === 'sug') {
    //     selectionParent.replaceWith(selectionParent.innerText)
    // }

    if (window.getSelection) {
      var sel = window.getSelection();
      if (!IsInArticle(sel.getRangeAt(0))) {
        return;
      }
    }

    setActiveHeading(value);
    for (let parageraph of getSelectedHeaderNodes()) {
      parageraph.setAttribute("heading", value);
    }
    for (let parageraph of getSelectedNodes()) {
      if (value != "div") {
        parageraph.setAttribute("heading", value);
      } else {
        parageraph.removeAttribute("heading");
      }
    }

    //onCommand('formatBlock', event.target.value)
  };

  const nextNode = (node) => {
    if (node.hasChildNodes()) {
      return node.firstChild;
    } else {
      while (node && !node.nextSibling) {
        node = node.parentNode;
      }
      if (!node) {
        return null;
      }
      return node.nextSibling;
    }
  };

  const getRangeSelectedHeaderFirstNodes = (range) => {
    var node = range.startContainer;
    var rangeNodes = [];

    while (node != null && node.tagName != "ARTICLE") {
      if (node.tagName == "DIV") {
        rangeNodes.push(node);
      }
      node = node.parentNode;
    }

    return rangeNodes;
  };
  const getRangeSelectedNodes = (range) => {
    var node = range.startContainer;
    var endNode = range.endContainer;

    while (
      node != null &&
      node.tagName != "ARTICLE" &&
      node.tagName != "DIV"
      // !(node.tagName == 'DIV' && node.parentNode?.tagName == 'ARTICLE')
    ) {
      node = node.parentNode;
    }
    if (node.tagName == "ARTICLE") {
      return [];
    }
    // Special case for a range that is contained within a single node
    // if (range.startContainer == endNode) {
    //     return [node];
    // }

    // Iterate nodes until we hit the end container
    var rangeNodes = [];
    while (node && node != endNode) {
      // if (node.tagName == 'DIV' && node.parentNode.tagName == 'ARTICLE') {
      //     rangeNodes.push(node);
      // }
      if (node.tagName == "DIV") {
        let spans = node.getElementsByTagName("span");
        for (let span of spans) {
          span.style.fontSize = null;
        }
        rangeNodes.push(node);
      }
      node = nextNode(node);
    }
    if (node == endNode && node.tagName == "DIV") {
      let spans = node.getElementsByTagName("span");
      for (let span of spans) {
        span.style.fontSize = null;
      }
      rangeNodes.push(node);
    }
    // // Add partially selected nodes at the start of the range
    // node = range.startContainer;
    // while (node && node != range.commonAncestorContainer) {
    //     rangeNodes.unshift(node);
    //     node = node.parentNode;
    // }

    return rangeNodes;
  };
  const getRangeSelectedNodesDIVandTD = (range) => {
    var node = range.startContainer;
    var endNode = range.endContainer;

    while (
      node != null &&
      node.tagName != "ARTICLE" &&
      node.tagName != "DIV" &&
      node.tagName != "TD"
      // !(node.tagName == 'DIV' && node.parentNode?.tagName == 'ARTICLE')
    ) {
      node = node.parentNode;
    }
    if (node.tagName == "ARTICLE") {
      return [];
    }
    // Special case for a range that is contained within a single node
    // if (range.startContainer == endNode) {
    //     return [node];
    // }

    // Iterate nodes until we hit the end container
    var rangeNodes = [];
    while (node && node != endNode) {
      // if (node.tagName == 'DIV' && node.parentNode.tagName == 'ARTICLE') {
      //     rangeNodes.push(node);
      // }
      if (node.tagName == "DIV") {
        let spans = node.getElementsByTagName("span");
        for (let span of spans) {
          span.style.fontSize = null;
        }
        rangeNodes.push(node);
      } else if (node.tagName == "TD") {
        let spans = node.getElementsByTagName("span");
        for (let span of spans) {
          span.style.fontSize = null;
        }
        rangeNodes.push(node);
      }
      node = nextNode(node);
    }
    if (node == endNode && (node.tagName == "DIV" || node.tagName == "TD")) {
      let spans = node.getElementsByTagName("span");
      for (let span of spans) {
        span.style.fontSize = null;
      }
      rangeNodes.push(node);
    }
    // // Add partially selected nodes at the start of the range
    // node = range.startContainer;
    // while (node && node != range.commonAncestorContainer) {
    //     rangeNodes.unshift(node);
    //     node = node.parentNode;
    // }

    return rangeNodes;
  };
  const getSelectedHeaderNodes = () => {
    try {
      if (window.getSelection) {
        var sel = window.getSelection();
        // if (!sel.isCollapsed) {
        return getRangeSelectedHeaderFirstNodes(sel.getRangeAt(0));
        //}
      }
    } catch {}
    return [];
  };
  const getSelectedNodes = () => {
    try {
      if (window.getSelection) {
        var sel = window.getSelection();
        // if (!sel.isCollapsed) {
        return getRangeSelectedNodes(sel.getRangeAt(0));
        //}
      }
    } catch {}
    return [];
  };
  const IsInArticle = (range) => {
    let isInArticle = range.startContainer.parentNode;
    while (isInArticle != null && isInArticle.tagName != "ARTICLE") {
      isInArticle = isInArticle.parentNode;
    }
    if (isInArticle != null && isInArticle?.tagName == "ARTICLE") {
      return true;
    }
    return false;
  };

  const wrap = (tag, remove = false) => {
    prevSelection = save();
    if (prevSelection) {
      if (!IsInArticle(prevSelection)) {
        return;
      }
      if (remove) {
        let node = prevSelection.startContainer.parentNode;
        while (
          node != null &&
          node.tagName != "ARTICLE" &&
          node.tagName != tag.toUpperCase()
        ) {
          node = node.parentNode;
        }
        if (node != null && node.tagName == tag.toUpperCase()) {
          node.outerHTML = node.innerHTML;
        }
        let sel = window.getSelection();
        sel.removeAllRanges();
        return;
      }
      let range = prevSelection;
      let el = document.createElement(tag);
      el.appendChild(range.cloneContents());
      range.deleteContents();
      range.insertNode(el);

      let node = el;
      let range1 = document.createRange();
      range1.setStart(node, 0);
      try {
        range1.setEnd(node, 1);
      } catch {}
      let sel = window.getSelection();
      sel.removeAllRanges();
      // sel.addRange(range1);
    }
    return;
  };
  const setTextAlignOnDiv = (align) => {
    try {
      //for the first node
      if (window.getSelection) {
        var sel = window.getSelection();
        // if (!sel.isCollapsed) {
        let node = sel.getRangeAt(0);
        while (
          node != null &&
          node.tagName != "ARTICLE" &&
          node.tagName != "TD"
        ) {
          if (node.tagName == "DIV" || node.tagName == "TD") {
            node.style.textAlign = align;
            break;
          }
          node = node.parentNode;
        }
        //for all node
        for (let el of getRangeSelectedNodesDIVandTD(sel.getRangeAt(0))) {
          if (el.tagName == "DIV" || el.tagName == "TD") {
            el.style.textAlign = align;
          }
        }
      }
    } catch {}
  };
  const handleTextAlignChanged = (textAlign) => {
    setActiveAlign(textAlign);
    setTextAlignOnDiv(textAlign);
    // switch (textAlign) {
    // case 'left':
    //     return onCommand('justifyLeft');
    // case 'center':
    //     return onCommand('justifyCenter');
    // case 'right':
    //     return onCommand('justifyRight');
    // case 'justify':
    //     return onCommand('justifyFull');
    // }
  };

  return (
    <div
      id={props.id}
      className={classes.ToolbarMaster + " " + props.className || ""}
      style={props.style || {}}
    >
      {(props.isOpenDropDownPanel || !props.isShowDropDownPanel) && (
        <div className={classes.Toolbar}>
          {/*<Autocomplete*/}
          {/*    id="combo-box-demo"*/}
          {/*    options={fonts}*/}
          {/*    getOptionLabel={(option) => option.title}*/}
          {/*    style={{ width: 300 }}*/}
          {/*    onChange={e => onCommand('fontName', e.target.innerText)}*/}
          {/*    renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}*/}
          {/*/>*/}
          {/* <FormControl className={classes.formControl}>
        <Select
          title="Font Family"
          style={{ width: 130, marginLeft: 10 }}
          value={fontFamily}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {fonts.map((font, index) => (
            <MenuItem key={index} value={font.title}>
              {font.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
          <FormControl className={classes.formControl} title="Header">
            <Select
              style={{ width: 100, marginLeft: 10, marginRight: 10 }}
              value={activeHeading}
              //onChange={handleHeadingChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {headings.map((heading, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleHeadingChange(heading.value);
                  }}
                  value={heading.value}
                >
                  {heading.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.divider} />
          <IconButton
            title={"Bold (" + getShortCut("Bold") + ")"}
            className={classes.ToolbarButton}
            onClick={() => {
              onCommand("bold");
              setIsBold((isBold) => !isBold);
            }}
            style={{ color: isBold ? activeColor : defaultColor }}
          >
            <FormatBoldIcon />
          </IconButton>
          <IconButton
            title={"Italic (" + getShortCut("Italic") + ")"}
            className={classes.ToolbarButton}
            onClick={() => {
              setIsItalic((isItalic) => !isItalic);
              onCommand("italic");
            }}
            style={{ color: isItalic ? activeColor : defaultColor }}
          >
            <FormatItalicIcon />
          </IconButton>
          <IconButton
            title={"Underline (" + getShortCut("underline") + ")"}
            className={classes.ToolbarButton}
            onClick={() => {
              setIsUnderline((isUnderline) => !isUnderline);
              onCommand("");
            }}
            style={{ color: isUnderline ? activeColor : defaultColor }}
          >
            <FormatUnderlinedIcon />
          </IconButton>
          <IconButton
            title={"Strikethrough (" + getShortCut("strikeThrough") + ")"}
            className={classes.ToolbarButton}
            onClick={() => {
              setIsStrikeThrough((isStrikeThrough) => !isStrikeThrough);
              onCommand("strikeThrough");
            }}
            style={{ color: isStrikeThrough ? activeColor : defaultColor }}
          >
            <FormatStrikethroughIcon />
          </IconButton>
          <IconButton
            title={"Superscript (" + getShortCut("superscript") + ")"}
            className={classes.ToolbarButton}
            onClick={() => onCommand("superscript")}
            style={{ color: defaultColor }}
          >
            <p style={{ fontWeight: "bold" }}>
              x
              <sup>
                <small>2</small>
              </sup>
            </p>
          </IconButton>
          <IconButton
            title={"subscript (" + getShortCut("subscript") + ")"}
            className={classes.ToolbarButton}
            onClick={() => onCommand("subscript")}
            style={{ color: defaultColor }}
          >
            <p style={{ fontWeight: "bold" }}>
              x
              <sub>
                <small>2</small>
              </sub>
            </p>
          </IconButton>
          <IconButton
            title={"Insert Formula (" + getShortCut("InsertFormula") + ")"}
            id="insertFormula"
            name="insertFormula"
            onClick={() => {
              handleClickOpenFormulaModel();
            }}
            style={{ color: isFormula ? activeColor : defaultColor }}
          >
            <FunctionsIcon />
          </IconButton>
          <IconButton
            title="Code"
            className={classes.ToolbarButton}
            onClick={() => {
              wrap("code", isCode);
              setIsCode(() => false);
            }}
            style={{ color: isCode ? activeColor : defaultColor }}
          >
            <CodeIcon />
          </IconButton>
          <IconButton
            title="Quote"
            className={classes.ToolbarButton}
            onClick={() => {
              wrap("q", isQuote);
              setIsQuote(() => false);
            }}
            style={{ color: isQuote ? activeColor : defaultColor }}
          >
            <FormatQuoteIcon />
          </IconButton>
          <div className={classes.divider} />
          <IconButton
            title="Align Left"
            className={classes.ToolbarButton}
            onClick={() => handleTextAlignChanged("left")}
            style={{
              color: activeAlign === "left" ? activeColor : defaultColor,
            }}
          >
            <FormatAlignLeftIcon />
          </IconButton>
          <IconButton
            title="Center"
            className={classes.ToolbarButton}
            onClick={() => handleTextAlignChanged("center")}
            style={{
              color: activeAlign === "center" ? activeColor : defaultColor,
            }}
          >
            <FormatAlignCenterIcon />
          </IconButton>
          <IconButton
            title="Align Right"
            className={classes.ToolbarButton}
            onClick={() => handleTextAlignChanged("right")}
            style={{
              color: activeAlign === "right" ? activeColor : defaultColor,
            }}
          >
            <FormatAlignRightIcon />
          </IconButton>
          <IconButton
            title="Justify"
            className={classes.ToolbarButton}
            onClick={() => handleTextAlignChanged("justify")}
            style={{
              color: activeAlign === "justify" ? activeColor : defaultColor,
            }}
          >
            <FormatAlignJustifyIcon />
          </IconButton>
          <div className={classes.divider} />
          <IconButton
            title="Bullet List"
            className={classes.ToolbarButton}
            onClick={() => onCommand("insertUnorderedList")}
            style={{ color: defaultColor }}
          >
            <FormatListBulletedIcon />
          </IconButton>
          <IconButton
            title="Number List"
            className={classes.ToolbarButton}
            onClick={() => onCommand("insertOrderedList")}
            style={{ color: defaultColor }}
          >
            <FormatListNumberedIcon />
          </IconButton>
          <div className={classes.divider} />
          <IconButton
            title="Increase Indent"
            className={classes.ToolbarButton}
            onClick={() => {
              prevSelection = save();
              try {
                if (prevSelection) {
                  let node = prevSelection.startContainer.parentNode;
                  if (!IsInArticle(prevSelection)) {
                    return;
                  }
                  while (
                    node != null &&
                    node.tagName != "ARTICLE" &&
                    node.tagName != "TD"
                  ) {
                    if (node.tagName == "DIV" || node.tagName == "TD") {
                      let paddingLeft = node.style.paddingLeft;

                      paddingLeft = paddingLeft?.replace("px", "");
                      paddingLeft = Number(paddingLeft);

                      if (
                        paddingLeft == null ||
                        paddingLeft == undefined ||
                        paddingLeft == NaN
                      ) {
                        paddingLeft = 40;
                      } else {
                        paddingLeft = paddingLeft + 40;
                      }
                      node.style.paddingLeft = paddingLeft + "px";

                      break;
                    }
                    node = node.parentNode;
                  }
                }
              } catch {}
              //onCommand('indent')
            }}
          >
            <FormatIndentIncreaseIcon />
          </IconButton>
          <IconButton
            title="Decrease Indent"
            className={classes.ToolbarButton}
            onClick={() => {
              prevSelection = save();
              try {
                if (prevSelection) {
                  if (!IsInArticle(prevSelection)) {
                    return;
                  }
                  let node = prevSelection.startContainer.parentNode;
                  while (
                    node != null &&
                    node.tagName != "ARTICLE" &&
                    node.tagName != "TD"
                  ) {
                    if (node.tagName == "DIV" || node.tagName == "TD") {
                      let paddingLeft = node.style.paddingLeft;
                      paddingLeft = paddingLeft?.replace("px", "");
                      paddingLeft = Number(paddingLeft);
                      if (
                        paddingLeft == null ||
                        paddingLeft == undefined ||
                        paddingLeft == NaN
                      ) {
                        paddingLeft = 0;
                      } else {
                        paddingLeft = paddingLeft - 40;
                      }
                      node.style.paddingLeft =
                        (paddingLeft < 0 ? 0 : paddingLeft) + "px";
                      // let indents = node.getElementsByTagName("indent");
                      // if (indents[0]) {
                      //     indents[0].remove();
                      // }
                      break;
                    }
                    node = node.parentNode;
                  }
                }
              } catch {}
              //onCommand('outdent')
            }}
          >
            <FormatIndentDecreaseIcon />
          </IconButton>

          <div className={classes.divider} />
          <IconButton
            title="Image"
            id="imageProject"
            name="imageProject"
            aria-controls={"basic-menu"}
            aria-haspopup="true"
            aria-expanded={"true"}
            onClick={handleClickElmentForImageToolbar}
            style={{
              color: defaultColor,
              textTransform: "none",
              // width: '100px',
            }}
          >
            <PhotoLibraryIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            style={{ width: "100%" }}
            anchorEl={elmentForImageToolbar}
            open={isOpenElmentForImageToolbar}
            onClose={handleCloseElmentForImageToolbar}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem style={{ width: "100%", padding: "0px" }}>
              <Button
                variant="contained"
                component="label"
                style={{
                  textTransform: "none",
                  padding: "13px 20px",
                  width: "100%",
                  background: "no-repeat",
                  border: "0px none transparent",
                  boxShadow: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#eb3449",
                  fontWeight: "500",
                }}
              >
                Upload Files
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  multiple
                  onChange={handleFileSelect}
                />
                <CloudUploadIcon />
              </Button>
            </MenuItem>
            <hr></hr>
            <MenuItem
              style={{
                width: "320px",
                cursor: "default",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                maxHeight: "230px",
                overflowY: "auto",
              }}
              className={"liboScroll"}
            >
              {props.projectFiles.length === 0 && (
                <span style={{ color: "#aea8a8" }}>No images ...</span>
              )}
              {props.projectFiles
                .sort((a, b) => b.id - a.id)
                .map((img) => (
                  <div
                    // draggable="true"
                    key={img.id}
                    title={img.name + "\nClick to copy the image link!"}
                    style={{
                      backgroundImage: `url(${img.file})`,
                    }}
                    className={classes.image}
                    onClick={() => {
                      if (insertImage(img.id, img.file)) {
                        handleCloseElmentForImageToolbar();
                      }
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onProjectFileDelete(img.id);
                      }}
                      className={classes.imageDeleteIcon}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                ))}
            </MenuItem>
          </Menu>
          <ButtonWithPopover
            id={"table"}
            title="Add Table"
            icon={GridOnIcon}
            onPopoverOpen={() => {
              /* TODO save */
              prevSelection = save();
            }}
            onPopoverClose={() => {
              setTableSize([-1, -1]);
            }}
            renderPopoverContent={(onClose) => {
              return (
                <div style={{ margin: 10 }}>
                  <div className={classes.MSKEditorTableTitle}>
                    {tableSize[1] === -1 || tableSize[0] === -1 ? (
                      "Insert Table"
                    ) : (
                      <>
                        {tableSize[1] + 1} &times; {tableSize[0] + 1} Table
                      </>
                    )}
                  </div>
                  <div className={classes.MSKEditorTable}>
                    {Array(10)
                      .fill(0)
                      .map((a, i) => {
                        return (
                          <div key={i} className={classes.MSKEditorTR}>
                            {Array(10)
                              .fill(0)
                              .map((b, j) => {
                                return (
                                  <div
                                    key={i + "_" + j}
                                    className={[
                                      classes.MSKEditorTD,
                                      ...(i <= tableSize[0] && j <= tableSize[1]
                                        ? [classes.active]
                                        : []),
                                    ].join(" ")}
                                    onMouseOver={() =>
                                      handleTableSizeChange(i, j)
                                    }
                                    onClick={() => {
                                      let table = createHTMLTable(
                                        tableSize[1] + 1,
                                        tableSize[0] + 1
                                      );
                                      restore(prevSelection);
                                      if (
                                        props.onAllowToInsertTable(
                                          prevSelection
                                        )
                                      ) {
                                        document.execCommand(
                                          "insertHTML",
                                          false,
                                          table
                                        );
                                        props.onOrderByTables();
                                      }
                                      onClose();
                                    }}
                                  />
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            }}
          />

          {isTable && (
            <>
              <IconButton
                title="Edit Table"
                className={classes.ToolbarButton}
                onClick={() => {
                  handleClickOpenTableModel();
                }}
              >
                <img width={"24px"} src={editTableIconSrc} />
              </IconButton>
              <IconButton
                title="Remove Table"
                className={classes.ToolbarButton}
                onClick={() => {
                  removeTableBlock();
                  props.onOrderByTables();
                }}
              >
                <img width={"24px"} src={removeTableSrc} />
              </IconButton>
              <span style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "0.7em" }}>col Width&nbsp;</span>
                <input
                  style={{
                    width: "60px",
                    textAlign: "center",
                    border: "0px none transparent",
                    background: "transparent",
                    borderBottom: "1px solid gray",
                  }}
                  id="txtColumnTableWidth"
                  type={"number"}
                  max={"100"}
                  min={"0"}
                  value={columnWidthElement}
                  onChange={(e) => {
                    let num = Number(e.target.value);
                    setColumnWidthElement(e.target.value);
                    if (columnElement && columnElement != null) {
                      if (columnIndexElement && columnIndexElement > -1) {
                        let table = columnElement.parentNode.parentNode;
                        let rows = table.getElementsByTagName("tr");
                        for (let row of rows) {
                          if (
                            num &&
                            num != NaN &&
                            num != undefined &&
                            num >= 0
                          ) {
                            row.getElementsByTagName("td")[
                              columnIndexElement
                            ].style.width = (num <= 100 ? num : 100) + "%";
                            let textwidth = (
                              (num <= 100 ? num : 100) / 100
                            ).toFixed(2);
                            row
                              .getElementsByTagName("td")
                              [columnIndexElement].setAttribute(
                                "textwidth",
                                textwidth
                              );
                          } else {
                            row.getElementsByTagName("td")[
                              columnIndexElement
                            ].style.width = "";
                            row
                              .getElementsByTagName("td")
                              [columnIndexElement].removeAttribute("textwidth");
                          }
                        }
                      }
                    }
                  }}
                />
                %
              </span>
              <IconButton
                title="Inset Column After"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    if (columnIndexElement > -1) {
                      let table = columnElement.parentNode.parentNode;
                      let rows = table.getElementsByTagName("tr");

                      // td.style.width = (100 / rows.length).toFixed(2) + '%';
                      for (let row of rows) {
                        try {
                          let td = document.createElement("td");
                          td.innerHTML = "&nbsp;";
                          row
                            .getElementsByTagName("td")
                            [columnIndexElement].after(td);
                        } catch {}
                      }
                    }
                  }
                }}
              >
                <img width={"21px"} src={colInsertAfterTableSrc} />
              </IconButton>
              <IconButton
                title="Inset Column Before"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    if (columnIndexElement > -1) {
                      let table = columnElement.parentNode.parentNode;
                      let rows = table.getElementsByTagName("tr");

                      for (let row of rows) {
                        try {
                          let td = document.createElement("td");
                          td.innerHTML = "&nbsp;";
                          row.insertBefore(
                            td,
                            row.getElementsByTagName("td")[columnIndexElement]
                          );
                        } catch {}
                      }
                      setColumnIndexElement(columnIndexElement + 1);
                    }
                  }
                }}
              >
                <img width={"21px"} src={colInsertBeforeTableSrc} />
              </IconButton>
              <IconButton
                title="Remove Column"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    if (columnIndexElement > -1) {
                      let tablediv = columnElement;
                      while (
                        tablediv != null &&
                        !(
                          tablediv.tagName == "DIV" &&
                          tablediv.hasAttribute("tablecaption")
                        )
                      ) {
                        tablediv = tablediv.parentNode;
                      }

                      let table = columnElement.parentNode.parentNode;
                      let rows = table.getElementsByTagName("tr");
                      for (let row of rows) {
                        try {
                          row
                            .getElementsByTagName("td")
                            [columnIndexElement]?.remove();
                        } catch {}
                      }

                      if (
                        tablediv != null &&
                        tablediv.getElementsByTagName("td").length == 0
                      ) {
                        tablediv.remove();
                        props.onOrderByTables();
                      }
                      handleCloseTableModel();
                      setIsTable(false);
                    }
                  }
                }}
              >
                <img width={"24px"} src={removeColTableSrc} />
              </IconButton>
              <IconButton
                title="Inset Row After"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    let tr = document.createElement("tr");
                    tr.innerHTML = columnElement.parentNode.innerHTML;
                    let tds = tr.getElementsByTagName("td");
                    for (let td of tds) {
                      td.innerHTML = "&nbsp;";
                    }
                    columnElement.parentNode.after(tr);
                  }
                }}
              >
                <img width={"21px"} src={rowInsertAfterTableSrc} />
              </IconButton>
              <IconButton
                title="Inset Row Above"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    let tr = document.createElement("tr");
                    tr.innerHTML = columnElement.parentNode.innerHTML;
                    let tds = tr.getElementsByTagName("td");
                    for (let td of tds) {
                      td.innerHTML = "&nbsp;";
                    }
                    columnElement.parentNode.parentNode.insertBefore(
                      tr,
                      columnElement.parentNode
                    );
                  }
                }}
              >
                <img width={"21px"} src={rowInsertAboveTableSrc} />
              </IconButton>
              <IconButton
                title="Remove Row"
                className={classes.ToolbarButton}
                onClick={() => {
                  if (columnElement && columnElement != null) {
                    let tablediv = columnElement;
                    while (
                      tablediv != null &&
                      !(
                        tablediv.tagName == "DIV" &&
                        tablediv.hasAttribute("tablecaption")
                      )
                    ) {
                      tablediv = tablediv.parentNode;
                    }
                    columnElement.parentNode?.remove();
                    if (
                      tablediv != null &&
                      tablediv.getElementsByTagName("tr").length == 0
                    ) {
                      tablediv.remove();
                      props.onOrderByTables();
                    }
                    handleCloseTableModel();
                    setIsTable(false);
                  }
                }}
              >
                <img width={"21px"} src={rowRemoveTableSrc} />
              </IconButton>
            </>
          )}
          <div className={classes.divider} />
          <ButtonWithPopover
            title="Add Link"
            id={"createLink"}
            icon={LinkIcon}
            onPopoverOpen={() => (prevSelection = save())}
            onPopoverClose={() => setLink("")}
            renderPopoverContent={(onClose) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    InputProps={{
                      className: "inputFont",
                    }}
                    style={{ margin: 10 }}
                    id="outlined-basic"
                    label="Link"
                    variant="outlined"
                    placeholder={"https://"}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      restore(prevSelection);
                      onCommand("createLink", link);
                      onClose();
                    }}
                  >
                    Done
                  </Button>
                </div>
              );
            }}
          />
          <IconButton
            title="Remove Link"
            className={classes.ToolbarButton}
            onClick={() => onCommand("unlink")}
          >
            <LinkOffIcon />
          </IconButton>
          {/*</IconButton>*/}

          <div className={classes.divider} />
          <IconButton
            title="Horizontal Line"
            className={classes.ToolbarButton}
            onClick={() => {
              document.execCommand("insertHTML", false, "<hr />");
            }}
          >
            <RemoveIcon />
          </IconButton>
          {/* <div className={classes.divider} />
            <IconButton className={classes.ToolbarButton} onClick={() => onCommand('delete')}>
                <DeleteForeverIcon />
            </IconButton> */}
          <div className={classes.divider} />
          <IconButton
            title="Undo"
            className={classes.ToolbarButton}
            onClick={() => onCommand("undo")}
          >
            <UndoIcon />
          </IconButton>
          <IconButton
            title="redo"
            className={classes.ToolbarButton}
            onClick={() => onCommand("redo")}
          >
            <RedoIcon />
          </IconButton>
          <div className={classes.divider} />
          {/*<IconButton className={classes.ToolbarButton} onClick={() => {*/}
          {/*    console.log('Test');*/}
          {/*    var range = window.getSelection().getRangeAt(0);*/}
          {/*    console.log(range)*/}
          {/*    var selectionContents = range.extractContents();*/}
          {/*    var span = document.createElement("span");*/}
          {/*    span.className = 'sug'*/}
          {/*    span.style.color = "black";*/}
          {/*    span.appendChild(selectionContents);*/}
          {/*    range.insertNode(span);*/}
          {/*    // var span = document.createElement("span");*/}
          {/*    // span.style.fontWeight = "bold";*/}
          {/*    // span.style.color = "red";*/}
          {/*    // if (window.getSelection) {*/}
          {/*    //     var sel = window.getSelection();*/}
          {/*    //     if (sel.rangeCount) {*/}
          {/*    //         var range = sel.getRangeAt(0).cloneRange();*/}
          {/*    //         range.surroundContents(span);*/}
          {/*    //         sel.removeAllRanges();*/}
          {/*    //         sel.addRange(range);*/}
          {/*    //     }*/}
          {/*    // }*/}
          {/*}}>*/}
          {/*    T*/}
          {/*</IconButton>*/}
          <div style={{ display: "flex", alignItems: "center" }}>
            {props.ShowSetting && (
              <IconButton
                title="Setting"
                className={classes.ToolbarButton}
                onClick={() => {
                  props.onSetIsShowSettingPannel(!props.isShowSettingPannel);
                }}
                style={{
                  color: props.isShowSettingPannel ? activeColor : defaultColor,
                }}
              >
                <SettingsIcon />
              </IconButton>
            )}
            <IconButton
              title={"Save (" + getShortCut("Save") + ")"}
              className={classes.ToolbarButton}
              onClick={props.handleOnSaveClick}
            >
              <SaveIcon />
            </IconButton>
            {props.allowToShare && (
              <IconButton
                title={"Share project"}
                className={classes.ToolbarButton}
                onClick={() => props.handleOpenShareDialog(props.projectId)}
              >
                <ShareIcon style={{ color: defaultColor }} />
              </IconButton>
            )}
            <IconButton
              title="Export Project"
              id="exprtProject"
              name="exprtProject"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickExport}
              style={{
                color: defaultColor,
                textTransform: "none",
                // width: '100px',
              }}
            >
              <CloudDownloadIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              style={{ width: "100%" }}
              anchorEl={exportElment}
              open={open}
              onClose={handleCloseExport}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {ExportType.map((item, index) => (
                <MenuItem
                  key={index}
                  style={{ width: "100%" }}
                  onClick={() => {
                    props.ExportLiboberryGenerator(item.value);
                    handleCloseExport();
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <FormulaDialog
            title={"FormulaEditInsertDialog"}
            key={"FormulaEditInsertDialog"}
            open={isInsertFormulaModelOpen}
            handleCloseFormulaModel={handleCloseFormulaModel}
            projectId={props.projectId}
            handleClickRemove={() => {
              removeLatexFormule();
              handleCloseFormulaModel();
            }}
            handleClickAccept={(value, label, inline, Html) => {
              InsertFormulaInEditor(value, label, inline, Html);
              handleCloseFormulaModel();
            }}
            showRemove={removeFormula}
            inline={latexInline}
            latexFormula={latexFormulaValue}
            label={latexLabelValue}
          />
          {isEditTableModelOpen && (
            <Dialog
              classes={{ paper: { background: "red" } }}
              open={isEditTableModelOpen}
              onClose={handleCloseTableModel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                style={{ color: "white", backgroundColor: "#eb3449" }}
                id="alert-dialog-title"
              >
                {"Edit Table"}
              </DialogTitle>
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  InputProps={{
                    className: "inputFont",
                  }}
                  id="txtTableCaption"
                  label="Table Caption"
                  variant="outlined"
                  style={{ marginTop: "30px" }}
                  disabled={false}
                  defaultValue={tableEditProperties.caption}
                  onChange={(e) =>
                    setTableEditProperties({
                      caption: e.target.value,
                      label: tableEditProperties.label,
                      size: tableEditProperties.size,
                    })
                  }
                />
                <TextField
                  InputProps={{
                    className: "inputFont",
                  }}
                  id="txtTableLabel"
                  label="Table Label"
                  variant="outlined"
                  style={{ marginTop: "30px" }}
                  disabled={false}
                  defaultValue={tableEditProperties.label}
                  onChange={(e) => {
                    setTableEditProperties({
                      caption: tableEditProperties.caption,
                      label: e.target.value,
                      size: tableEditProperties.size,
                    });
                  }}
                />
                <br />
                <FormControl
                  component="fieldset"
                  style={{
                    padding: "5px 0px 5px 8px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                >
                  <FormLabel
                    component="legend"
                    style={{
                      fontSize: "0.75em",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    Table Size
                  </FormLabel>
                  <RadioGroup
                    aria-label="ddlTableSize"
                    name="ddlTableSize"
                    value={tableEditProperties.size}
                    onChange={(e) => {
                      setTableEditProperties({
                        caption: tableEditProperties.caption,
                        label: tableEditProperties.label,
                        size: e.target.value,
                      });
                    }}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {TableSizes.map((type, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            style={{ whiteSpace: "nowrap", fontSize: "0.9rem" }}
                            value={type.key}
                            control={<Radio />}
                            label={
                              <span style={{ fontSize: "0.92rem" }}>
                                {type.label}
                              </span>
                            }
                          />
                        );
                      })}
                    </div>
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseTableModel}
                  className={"liboBtnCancel"}
                >
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    removeTableBlock();
                    props.onOrderByTables();
                    handleCloseTableModel();
                  }}
                  className={"liboBtnRemove"}
                >
                  Remove
                </Button>

                <Button
                  onClick={() => {
                    if (columnElement && columnElement != null) {
                      let td = columnElement;
                      let tablediv = td;
                      while (
                        tablediv != null &&
                        !(
                          tablediv.tagName == "DIV" &&
                          tablediv.hasAttribute("tablecaption")
                        )
                      ) {
                        tablediv = tablediv.parentNode;
                      }
                      if (tablediv == null) {
                        handleCloseTableModel();
                        return;
                      }
                      let table = tablediv.getElementsByTagName("table");
                      if (table != null && table.length != 0) {
                        table = table[0];
                      } else {
                        handleCloseTableModel();
                        return;
                      }
                      table.style.maxWidth = tableEditProperties.size;
                      let textwidth = (
                        Number(tableEditProperties.size.replace("%", "")) / 100
                      ).toFixed(2);
                      table.setAttribute("textwidth", textwidth);
                      table.setAttribute("title", tableEditProperties.label);

                      let captions =
                        tablediv.getElementsByTagName("tablecaption");
                      if (captions.length > 0) {
                        let caption = captions[0];
                        caption.innerText = tableEditProperties.caption;
                      } else {
                        var text = document.createElement("tablecaption");
                        text.innerHTML = tableEditProperties.caption;
                        tablediv.appendChild(text);
                      }
                    }
                    handleCloseTableModel();
                  }}
                  className={"liboBtnApprove"}
                  autoFocus
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      )}
      {props.isShowDropDownPanel && (
        <div className={classes.dropdownPanel}>
          <div
            title={props.isOpenDropDownPanel ? "Hide Toolbar" : "Show Toolbar"}
            onClick={props.onClickDropDownPanel}
            className={classes.dropdownboxPanel}
          >
            {props.isOpenDropDownPanel ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
        </div>
      )}
      <div
        id={props.id}
        className={classNames(classes.ToolbarMaster)}
        style={props.style}
      >
        {isShowHeadersItem && (
          <div className={classes.FloatItemPanel}>
            <div
              title={isOpenHeadersPanel ? "Hide headers" : "Show headers"}
              onClick={handleHeaderPreviewClick}
              className={classes.FloatItem}
            >
              <HeaderPreview html={htmlContent} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Toolbar;
Toolbar.defaultProps = {
  onAllowToInsertTable: () => {
    return true;
  },
  onAllowToInsertFormula: () => {
    return true;
  },
  onClickDropDownPanel: () => {},
  onOrderByTables: () => {},
  ExportLiboberryGenerator: () => {},
  handleOnSaveClick: () => {},
  isShowSettingPannel: false,
  onSetIsShowSettingPannel: () => {},
  onResizeEditor: () => {},
  ShowSetting: false,
  id: "",
  projectId: 0,
  allowToShare: false,
  isShowDropDownPanel: false,
  isOpenDropDownPanel: false,
  isShowHeadersItem: true,
  isOpenHeadersPanel: false,
  onClickHeadersPanel: () => {},
  openFormula: null,
  setLiboMathClickingInLiboEditor: () => {},
  cursorPostion: () => {},
  handleOpenShareDialog: () => {},

  projectFiles: [],
  onProjectFileDelete: () => {},
  onFilesUploadButtonClick: () => {},
  onOrderByFigures: () => {},
  onPositionImage: () => {},
  onPositionImageValue: null,
};
Toolbar.propTypes = {
  htmlContent: PropTypes.string.isRequired,
};
