import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import PopUpIconButton from "../../PopUpIconButton/PopUpIconButton";
import AiTyping from "../../MSKEditor/Components/AISuggestion/AiTyping";
import AIAsk from "./AIAsk";
import classes from "./AIAsk.module.css";
import "../../LiboBerryCss/liboBerry.css";
import { toast } from "react-toastify";
import RefreshIcon from "@material-ui/icons/Refresh";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ReplyIcon from "@material-ui/icons/Reply";
import AIIcon from "../../Icons/AIIcon";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import ClearIcon from "../../Icons/clearIcon";
const AIAssistentPopUp = (props) => {
  const [aiLoading, setAiLoading] = useState(null);
  const [askValue, setAskValue] = useState("");
  const [startPopup, setStartPopup] = useState(true);
  const [replayItem, setReplayItem] = useState(null);
  const [historyTarget, setHistoryTarget] = useState(null);

  useEffect(() => {
    if (props.clearAskAi) {
      props.setClearAskAi(false);
      setAskValue("");
      setReplayItem(null);
      if (props.IntializeAiAssistentRemoveAiPanel) {
        setAiLoading(null);
        setStartPopup(true);
      }
    }
  }, [props.clearAskAi]);

  useEffect(() => {
    if (aiLoading == "success") {
      if (props.aiResult != null && props.aiResult != "") {
        let list = [];
        list.push({
          type: "result",
          text: props.aiResult,
          show: false,
          bookmark: false,
          replayItem: null,
          bookmarkId: null,
          bookmarkTitle: "",
          bookmarkTags: [],
        });
        props.AddLiboAiHistory(list);
      }
    }
  }, [aiLoading]);
  const getUserInfoName = () => {
    let name = "";
    if (props.userInfo) {
      if (
        props.userInfo.first_name != null &&
        props.userInfo.first_name.trim() != ""
      ) {
        name += props.userInfo.first_name.trim().substring(0, 1);
      }
      if (
        props.userInfo.last_name != null &&
        props.userInfo.last_name.trim() != ""
      ) {
        name += props.userInfo.last_name.trim().substring(0, 1);
      }
      if (name.trim() == "") {
        name = props.userInfo.email.trim().substring(0, 2);
      }
    }
    return name.trim();
  };
  useEffect(() => {
    props.ShowAllHistory();
    return () => {
      props.setShowAsk(false);
    };
  }, []);
  const replayInnerTextData = (txt) => {
    let div = document.createElement("div");
    div.innerHTML = txt;
    return div.innerText.trim();
  };
  const copyHtmlFromDiv = (div) => {
    if (div) {
      // Create a range and selection
      const range = document.createRange();
      range.selectNodeContents(div);

      const selection = window.getSelection();
      selection.removeAllRanges(); // Clear previous selections
      selection.addRange(range); // Select the content of the div

      // Copy the selection to the clipboard
      try {
        const successful = document.execCommand("copy");
        if (!successful) {
          console.error("Unable to copy HTML");
        }
      } catch (err) {
        console.error("Failed to execute copy command: ", err);
      }

      // Clear the selection
      selection.removeAllRanges();
    } else {
      console.error("Element not found");
    }
  };

  return (
    <PopUpIconButton
      showClearIcon={true}
      ClearContent={() => {
        props.ClearHistory(null);
      }}
      onMouseDown={(e) => {
        setHistoryTarget(e.target);
      }}
      showPopup={props.showAsk}
      onSetShowPopup={(value) => {
        if (!value) {
          props.Close();
        }
        props.setShowAsk(value);
      }}
      showbtnPopup={false}
      customValue={
        <>
          {props.liboAiHistory != null &&
            props.liboAiHistory.length > 0 &&
            props.liboAiHistory
              .filter((s) => s.show == true)
              .map((item, index) => {
                return (
                  <div
                    key={item.number + "_" + index}
                    id={item.number + "_" + item.type}
                    className={
                      item.type == "ask"
                        ? classes.AskQustion
                        : classes.AskAnswer
                    }
                  >
                    <>
                      {item.type == "ask" && (
                        <>
                          <div
                            className={classes.shareProfileIcon}
                            style={{
                              backgroundImage:
                                props.userInfo &&
                                props.userInfo.profile_image != null
                                  ? "url('" +
                                    props.userInfo.profile_image +
                                    "')"
                                  : "none",
                              backgroundColor:
                                props.userInfo &&
                                props.userInfo.profile_image != null
                                  ? "transparent"
                                  : "#e5e5e5",
                            }}
                          >
                            {props.userInfo &&
                              props.userInfo.profile_image == null && (
                                <>{getUserInfoName()}</>
                              )}
                          </div>
                          <div className={classes.AskQustion_text}>
                            {item.replayItem && (
                              <div
                                className={classes.AskQustion_Replay_box}
                                onClick={() => {
                                  if (item.replayItem) {
                                    let _id =
                                      item.replayItem.number +
                                      "_" +
                                      item.replayItem.type;
                                    let doc = document.getElementById(_id);
                                    if (doc) {
                                      doc.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                    }
                                  }
                                }}
                                title={replayInnerTextData(
                                  item.replayItem.text
                                )}
                              >
                                {replayInnerTextData(item.replayItem.text)}
                              </div>
                            )}
                            {item.text}
                          </div>
                          <div className={classes.AskQustion_BookMarkIcon}>
                            <div
                              title="BookMark"
                              onClick={() => {
                                props.taggleBookMark(item);
                              }}
                            >
                              {!item.bookmark && (
                                <BookmarkBorderIcon
                                  style={{ width: "20px", height: "20px" }}
                                />
                              )}
                              {item.bookmark && (
                                <BookmarkIcon
                                  style={{ width: "20px", height: "20px" }}
                                />
                              )}
                            </div>
                            <div
                              onClick={() => {
                                props.ClearHistory(item);
                              }}
                              title="Clear"
                              style={{ margin: " 0px 10px 0px 0px" }}
                            >
                              <ClearIcon color="#7b6bc9" width="20px" />
                            </div>
                          </div>
                        </>
                      )}
                      {item.type == "result" && (
                        <>
                          <div className={classes.AskAnswer_panel}>
                            <div style={{ width: "30px" }}>
                              <img
                                src="/burryIcon.png"
                                style={{ width: "23px" }}
                              />
                            </div>
                            <div
                              className={
                                classes.AskAnswer_box +
                                " " +
                                (props.editor == "CE"
                                  ? classes.OverFlowX + " liboScroll"
                                  : "")
                              }
                            >
                              <AiTyping
                                editor={props.editor}
                                Caption={"Libo AI"}
                                status={"success"}
                                // askValue={askValue}
                                // content={props.aiContent}
                                onStatus={() => {}}
                                onResult={() => {}}
                                result={item.text}
                                type={"prompt"}
                                flag={props.editor}
                                ProjectId={props.projectid}
                              />
                            </div>
                          </div>
                          <div className={classes.AskAnswer_Icon_Panel}>
                            <div className={classes.AskAnswer_Icon_box}>
                              <div
                                title="Insert"
                                style={{ paddingRight: "7px" }}
                                className={classes.AskAnswer_Icon_box_btn}
                                onClick={() => {
                                  if (props.AiReplaceClick(item.text)) {
                                    props.RemoveAiPanel();
                                  }
                                }}
                              >
                                <BeenhereIcon
                                  style={{
                                    color: "#7b6bc9",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              </div>
                              <div
                                className={classes.AskAnswer_Icon_box_btn}
                                title={"Copy"}
                                onClick={(e) => {
                                  let div = document.getElementById(
                                    item.number + "_" + item.type
                                  );
                                  if (div) {
                                    let divs = document.getElementsByClassName(
                                      classes.AskAnswer_box
                                    );
                                    if (divs && divs.length > 0) {
                                      copyHtmlFromDiv(divs[0]);
                                      toast.success("Copied Successfully!");
                                    }
                                  }
                                }}
                              >
                                <FileCopyIcon
                                  style={{
                                    color: "#7b6bc9",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              </div>
                              <div
                                title="Replay"
                                style={{ paddingLeft: "7px" }}
                                className={classes.AskAnswer_Icon_box_btn}
                                onClick={() => {
                                  setReplayItem(item);
                                }}
                              >
                                <ReplyIcon
                                  style={{
                                    color: "#7b6bc9",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              </div>
                              <div
                                title="Clear"
                                style={{ paddingLeft: "7px" }}
                                className={classes.AskAnswer_Icon_box_btn}
                                onClick={() => {
                                  props.ClearHistory(item);
                                }}
                              >
                                <ClearIcon color="#7b6bc9" width="16px" />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                );
              })}
          {props.liboAiHistory == null ||
            (props.liboAiHistory.length == 0 && (
              <div style={{ minHeight: "100px" }}>
                {/* Welcome to Libo AI! I'm here to help you with any questions you
              have. Just let me know what you need assistance with, and I'll do
              my best to provide you with the information or support you're
              looking for. Don't hesitate to ask! */}
              </div>
            ))}
          {props.liboAiHistory != null &&
            props.liboAiHistory.length != 0 &&
            !startPopup && (
              <>
                <div style={{ padding: "5px" }}>
                  <div
                    style={{ position: "relative" }}
                    className={
                      (aiLoading == "success" &&
                        props.aiResult != null &&
                        props.aiResult != "") ||
                      aiLoading == "loading"
                        ? classes.AskAnswer_panel
                        : ""
                    }
                  >
                    {((aiLoading == "success" &&
                      props.aiResult != null &&
                      props.aiResult != "") ||
                      aiLoading == "loading") && (
                      <div style={{ width: "30px" }}>
                        <img src="/burryIcon.png" style={{ width: "23px" }} />
                      </div>
                    )}
                    <div
                      id="aiTypingPrompt_Box"
                      className={
                        (aiLoading == "success" &&
                          props.aiResult != null &&
                          props.aiResult != "") ||
                        aiLoading == "loading"
                          ? classes.AskAnswer_box +
                            " " +
                            (props.editor == "CE"
                              ? classes.OverFlowX + " liboScroll"
                              : "")
                          : ""
                      }
                    >
                      <AiTyping
                        loadingType={"typing"}
                        editor={props.editor}
                        Caption={"Libo AI"}
                        status={aiLoading}
                        askValue={askValue}
                        content={props.aiContent}
                        onStatus={setAiLoading}
                        onResult={props.setAiResult}
                        result={props.aiResult}
                        type={"prompt"}
                        flag={props.editor}
                        ProjectId={props.projectid}
                      />
                    </div>
                    {aiLoading == "success" &&
                      props.aiResult != null &&
                      props.aiResult != "" && (
                        <div
                          style={{ position: "absolute", bottom: "-10px" }}
                          className={classes.AskAnswer_Icon_Panel}
                        >
                          <div className={classes.AskAnswer_Icon_box}>
                            <div
                              className={classes.AskAnswer_Icon_box_btn}
                              style={{ paddingLeft: "12px" }}
                              title={"Copy"}
                              onClick={(e) => {
                                let div =
                                  document.getElementById("aiTypingPrompt_Box");
                                if (div) {
                                  copyHtmlFromDiv(div);
                                  toast.success("Copied Successfully!");
                                }
                              }}
                            >
                              <FileCopyIcon
                                style={{
                                  color: "#7b6bc9",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    padding: "13px 0px 33px 0px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {aiLoading == "success" &&
                      props.aiResult != null &&
                      props.aiResult != "" && (
                        <>
                          <div>
                            <input
                              type="button"
                              value={"Insert"}
                              className={"liboBtnApprove"}
                              onClick={() => {
                                if (props.AiReplaceClick()) {
                                  props.RemoveAiPanel();
                                }
                              }}
                            ></input>
                          </div>
                        </>
                      )}
                    {((aiLoading == "success" &&
                      props.aiResult != null &&
                      props.aiResult != "") ||
                      aiLoading == "error") && (
                      <>
                        <div>
                          <a
                            className={"liboBtnRefreshWithIcon"}
                            onClick={() => {
                              setAiLoading(
                                aiLoading == "error" ? "retry" : "regenerate"
                              );
                            }}
                          >
                            <>
                              {aiLoading == "error" && (
                                <>
                                  Try Again{" "}
                                  <RefreshIcon
                                    style={{ width: "0.8em" }}
                                    className={"rotate360"}
                                  />
                                </>
                              )}
                              {aiLoading == "success" && (
                                <>
                                  Regenerate{" "}
                                  <AutorenewIcon
                                    style={{
                                      width: "0.8em",
                                      marginLeft: "3px",
                                    }}
                                    className={"rotate360"}
                                  />
                                </>
                              )}
                              &nbsp;
                            </>
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
        </>
      }
      footerValue={
        <AIAsk
          promptLabels={props.promptLabels}
          EditBookMark={props.EditBookMark}
          historyTarget={historyTarget}
          setHistoryTarget={setHistoryTarget}
          bookmarkList={props.bookmarkList}
          replayItem={replayItem}
          disable={aiLoading == "loading"}
          RemoveAiPanel={props.RemoveAiPanel}
          removeReplayItem={() => {
            setReplayItem(null);
          }}
          removeBookMark={props.removeBookMark}
          AiAssistentClick={(value) => {
            let list = [];
            if (props.AiAssistentClick(value)) {
              setAskValue(value);
              let bookmark = {
                bookmarkId: null,
                bookmarkTitle: "",
                bookmarkTags: [],
                bookmark: false,
              };
              let bookmarksList = props.bookmarkList.filter(
                (s) =>
                  s.prompt_text.trim().toLowerCase() ==
                  value.trim().toLowerCase()
              );
              if (bookmarksList != null && bookmarksList.length > 0) {
                bookmark.bookmark = true;
                bookmark.bookmarkId = bookmarksList[0].id;
                bookmark.bookmarkTitle = bookmarksList[0].prompt_title;
                bookmark.bookmarkTags = bookmarksList[0].tags;
              }
              props.setShowAsk(true);
              list.push({
                type: "ask",
                text: value,
                show: true,
                bookmark: bookmark.bookmark,
                replayItem: replayItem,
                bookmarkId: bookmark.bookmarkId,
                bookmarkTitle: bookmark.bookmarkTitle,
                bookmarkTags: bookmark.bookmarkTags,
              });

              props.AddLiboAiHistory(list, true);
              if (replayItem) {
                props.setAiContent(replayItem.text);
              } else if (startPopup) {
                props.getContent();
              } else {
                let resultList = props.liboAiHistory.filter(
                  (s) => s.type == "result"
                );
                if (resultList.length > 0) {
                  props.setAiContent(resultList[resultList.length - 1].text);
                } else {
                  props.getContent();
                }
              }
              setReplayItem(null);
              setAiLoading(null);
              setStartPopup(false);
              return true;
            }
            props.setShowAsk(true);
            return false;
          }}
          SelectionStatus={props.SelectionStatus}
          Close={() => {
            props.Close();
          }}
        />
      }
      title={"Libo AI"}
      IconTitle={<AIIcon color={"#fbaf46"} />}
      pBottom="55px"
      pRight="20px"
      bBottom="0px"
      bRight="0px"
      bWidth="25px"
    />
  );
};

export default AIAssistentPopUp;
AIAssistentPopUp.propTypes = {
  SelectionStatus: PropTypes.string,
  Close: PropTypes.func,
  RemoveAiPanel: PropTypes.func,
  AiAssistentClick: PropTypes.func,
  projectid: PropTypes.number,
  aiResult: PropTypes.object,
  setAiResult: PropTypes.func,
  aiContent: PropTypes.string,
  setShowAsk: PropTypes.func,
  showAsk: PropTypes.bool,
  AiReplaceClick: PropTypes.func,
  clearAskAi: PropTypes.bool,
  setClearAskAi: PropTypes.func,
  editor: PropTypes.string,
  liboAiHistory: PropTypes.array,
  AddLiboAiHistory: PropTypes.func,
  ShowAllHistory: PropTypes.func,
  IntializeAiAssistentRemoveAiPanel: PropTypes.bool,
  taggleBookMark: PropTypes.func,
  setAiContent: PropTypes.func,
  getContent: PropTypes.func,
  bookmarkList: PropTypes.array,
  userInfo: PropTypes.object,
  removeBookMark: PropTypes.func,
  EditBookMark: PropTypes.func,
  promptLabels: PropTypes.array,
  ClearHistory: PropTypes.func,
};
AIAssistentPopUp.defaultProps = {
  SelectionStatus: "Document",
  Close: () => {},
  RemoveAiPanel: () => {},
  AiAssistentClick: () => {},
  projectid: 0,
  aiResult: null,
  setAiResult: () => {},
  aiContent: "",
  setShowAsk: () => {},
  showAsk: false,
  AiAssistentClick: () => {},
  AiReplaceClick: () => {},
  clearAskAi: false,
  setClearAskAi: () => {},
  editor: "VE",
  liboAiHistory: [],
  AddLiboAiHistory: () => {},
  ShowAllHistory: () => {},
  IntializeAiAssistentRemoveAiPanel: false,
  taggleBookMark: () => {},
  setAiContent: () => {},
  getContent: () => {},
  bookmarkList: [],
  userInfo: null,
  removeBookMark: () => {},
  EditBookMark: () => {},
  promptLabels: [],
  ClearHistory: () => {},
};
