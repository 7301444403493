import React, { useState, useEffect } from "react";
import propTypes from "prop-types";

import classes from "./LiboIdle.module.css";
import PropTypes from "prop-types";
import "../LiboBerryCss/liboBerry.css";
function LiboIdle(props) {
  const [secondsLeft, setSecondsLeft] = useState(props.time);
  const [intervalId, setIntervalId] = useState(null);
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, []);
  useEffect(() => {
    if (props.show) {
      setSecondsLeft(props.time);
      if (intervalId != null) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      let id = setInterval(() => {
        setSecondsLeft((sec) => sec - 1);
      }, 1000);
      setIntervalId(id);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [props.show]);
  useEffect(() => {
    if (secondsLeft <= 0) {
      props.Quit();
    }
  }, [secondsLeft]);
  return (
    <>
      {props.show && (
        <div className={classes.IdleOverlay}>
          <div className={classes.Idlemodal}>
            <h3 className={classes.IdleTitle}>
              It looks like it's been a while since you added anything new.
            </h3>
            <div className={classes.IdleContent}>
              You will be exiting the project in{" "}
              <b className={classes.IdleTimer}>{secondsLeft} </b>seconds. Would you like to refresh the project or continue working
              on it?
            </div>
            <div className={classes.Idlebuttons}>
              <button
                onClick={props.Continue}
                className={classes.Idlebutton + " " + "liboBtnCancel"}
              >
                Continue
              </button>
              <button
                onClick={props.Refresh}
                className={classes.Idlebutton + " " + "liboBtnApprove"}
              >
                Refresh
              </button>
              <button
                onClick={props.Quit}
                className={classes.Idlebutton + " liboBtnCancel"}
              >
                Exit project
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default LiboIdle;
LiboIdle.propTypes = {
  show: propTypes.bool,
  CloseIdle: propTypes.func,
  time: PropTypes.number,
  Quit: propTypes.func,
  Continue: propTypes.func,
  Refresh: propTypes.func,
};
LiboIdle.defaultProps = {
  show: false,
  time: 15, //15 sec
  CloseIdle: () => {},
  Quit: () => {},
  Continue: () => {},
  Refresh: () => {},
};
