import React, { useState } from "react";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import classes from "./Authors.module.css";
import propTypes from "prop-types";
import { updateProject } from "../../../Requests/Requests.js";
import { validateEmail } from "../../../Functions/liboFunction.js";
function CreateAuthors(props) {
  const [emailAutor, setEmailAutour] = useState(null);
  const [firstNameAutour, setFirstNameAutour] = useState(null);
  const [lastNameAutour, setLastNameAutour] = useState(null);
  const [isCorrespondingAutor, setIsCorrespondingAutor] = useState(false);

  const updateProjectInfo = (value, title) => {
    updateProject(props.projectid, value)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          } else if (title == "Add Autour") {
            toast.success(title + " successfully");
          }
        }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else {
          toast.error("Seems like there is a problem with the server");
          console.log(err);
        }
      });
  };
  const handleOnClickAddAutor = () => {
    let result = true;
    if (firstNameAutour == null || firstNameAutour?.trim() == "") {
      result = false;
      toast.error("Enter Autor First Name");
    }
    if (lastNameAutour == null || lastNameAutour?.trim() == "") {
      result = false;
      toast.error("Enter Autor Last Name");
    }
    if (emailAutor == null || emailAutor?.trim() == "") {
      result = false;
      toast.error("Enter Autor Email");
    } else if (!validateEmail(emailAutor?.trim())) {
      result = false;
      toast.error("The entered email format is not correct");
    }
    if (result) {
      let autors = props.AuthorsList;
      if (autors == null) autors = [];
      autors.push({
        surname: firstNameAutour,
        giveName: lastNameAutour,
        email: emailAutor,
        isCorresponding: isCorrespondingAutor,
      });

      updateProjectInfo({ authors: autors }, "Add Autour");
      props.setProjectInfo(autors);
      setFirstNameAutour("");
      setLastNameAutour("");
      setEmailAutour("");
      document.getElementById("giveName").value = "";
      document.getElementById("surname").value = "";
      document.getElementById("email").value = "";
      setIsCorrespondingAutor(false);
    }
  };
  return (
    <>
      <div className={classes.Box}>
        <TextField
          InputProps={{
            className: "inputFont",
          }}
          defaultValue={firstNameAutour}
          name="surname"
          id="surname"
          label="First Name"
          size="small"
          style={{ width: "100%" }}
          onBlur={(e) => {
            setFirstNameAutour(e.target.value.trim());
          }}
          req
          variant={"outlined"}
        />
      </div>
      <div className={classes.Box}>
        <TextField
          InputProps={{
            className: "inputFont",
          }}
          defaultValue={lastNameAutour}
          name="giveName"
          id="giveName"
          size="small"
          label="Last Name"
          style={{ width: "100%" }}
          onBlur={(e) => {
            setLastNameAutour(e.target.value.trim());
          }}
          req
          variant={"outlined"}
        />
      </div>
      <div className={classes.Box}>
        <TextField
          InputProps={{
            className: "inputFont",
          }}
          defaultValue={emailAutor}
          name="email"
          id="email"
          label="Email"
          size="small"
          style={{ width: "100%" }}
          onBlur={(e) => {
            setEmailAutour(e.target.value.trim());
          }}
          req
          variant={"outlined"}
        />
      </div>
      <div className={classes.Box}>
        <FormControlLabel
          label="Is Corresponding"
          control={
            <Checkbox
              id="isCorresponding"
              checked={isCorrespondingAutor}
              onChange={(val) => {
                setIsCorrespondingAutor(!isCorrespondingAutor);
              }}
            />
          }
        />
      </div>
      <div className={classes.Box}>
        <Button
          onClick={handleOnClickAddAutor}
          style={{
            color: "white",
            textTransform: "none",
            backgroundColor: "#eb3449",
            width: "calc(100% )",
          }}
        >
          Add Author
        </Button>
      </div>
    </>
  );
}
export default CreateAuthors;
CreateAuthors.propTypes = {
  AuthorsList: propTypes.array,
  projectid: propTypes.number,
  setProjectInfo: propTypes.func,
};
CreateAuthors.defaultProps = {
  AuthorsList: [],
  projectid: 0,
  setProjectInfo: (authors) => {},
};
