import React, { useState } from "react";
import PropTypes from "prop-types";
import classess from "./BottomMenu.module.css";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FeedBack from "../../../components/feedback/FeedBack.js";
import PopUpIconButton from "../../../components/PopUpIconButton/PopUpIconButton.js";
import AIIcon from "../../../components/Icons/AIIcon.js";
function BottomMenu(props) {
  const [showPopupBibliography, setShowPopupBibliography] = useState(false);
  return (
    <>
      <PopUpIconButton
        showPopup={showPopupBibliography}
        onSetShowPopup={setShowPopupBibliography}
        showbtnPopup={false}
        value={props.BibliographyValue}
        toolTip="Bibliography"
        title={props.editorType ? "Bibliography" : "References"}
        // imgUrl={refImgUrl}
        customValue={
          <div style={{ textAlign: "center", width: "100%" }}>
            No reference found!
          </div>
        }
        pBottom="45px"
        pRight="20px"
        bBottom="0px"
        bRight="40px"
        bWidth="25px"
      />
      <div
        className={
          classess.PanelBox +
          " " +
          (props.isShowSettingPannel
            ? classess.PanelBoxShowSettingPanel
            : classess.PanelBoxCloseSettingPanel)
        }
      >
        <div
          title="LiboBerry AI Assistant"
          onClick={props.AIClick}
          className={classess.box}
        >
          <AIIcon color={"#7b6bc9"} paddingTop={"4px"} />
        </div>
        <div
          title="Citations"
          onClick={props.CitationsClick}
          className={classess.box + " " + classess.bold}
        >
          @
        </div>
        <div
          className={classess.box}
          title="Bibliography"
          onClick={() => {
            props.BibliographyClick();
            props.onRefreshBiblography();
            setShowPopupBibliography(true);
          }}
        >
          <MenuBookIcon />
        </div>

        {/* <div
          className={classess.box}
          title="Headers"
          onClick={() => {
            props.onHeadersClick();
          }}
        >
          <HdIcon />
        </div> */}
        <div title={"Words count"} className={classess.box_noSelect}>
          <span style={{ fontWeight: "normal" }}>Words:&nbsp; </span>{" "}
          {props.getEditorWordCount()}
        </div>
        {props.vsCodeData.length > 0 && (
          <div
            style={{ fontSize: "0.8em" }}
            title="VsCode"
            onClick={() => {
              props.vsCodeChange(
                props.vsCodeData.filter((s) => s.showOn == props.vsCodeValue)[0]
                  ?.key
              );
              setShowPopupBibliography(false);
            }}
            className={classess.box + " " + classess.bold}
          >
            {
              props.vsCodeData.filter((s) => s.showOn == props.vsCodeValue)[0]
                ?.text
            }
          </div>
        )}

        <div
          onClick={() => {
            props.setShowPopupHelp(!props.showPopupHelp);
          }}
          className={classess.box_last}
        >
          <FeedBack
            setShowPopup={props.setShowPopupHelp}
            showPopup={props.showPopupHelp}
            bWidth="25px"
            bHeight="25px"
            position={"relative"}
            bgcolor={"transparent"}
            color="#7b6bc9"
            projectId={props.projectInfo?.id}
          />
        </div>
      </div>
    </>
  );
}

BottomMenu.propTypes = {
  BibliographyClick: PropTypes.func,
  CitationsClick: PropTypes.func,
  getEditorWordCount: PropTypes.func,
  projectInfo: PropTypes.object,
  setShowPopupHelp: PropTypes.func,
  showPopupHelp: PropTypes.bool,
  BibliographyValue: PropTypes.array,
  vsCodeChange: PropTypes.func,
  vsCodeData: PropTypes.array,
  vsCodeValue: PropTypes.string,
  onRefreshBiblography: PropTypes.func,
  editorType: PropTypes.func,
  AIClick: PropTypes.func,
};

BottomMenu.defaultProps = {
  BibliographyClick: () => {},
  CitationsClick: () => {},
  getEditorWordCount: () => {},
  projectInfo: null,
  setShowPopupHelp: () => {},
  showPopupHelp: false,
  BibliographyValue: [],
  isShowSettingPannel: false,
  vsCodeChange: () => {},
  vsCodeData: [],
  vsCodeValue: "",
  editorType: "",
  AIClick: () => {},
  onRefreshBiblography: () => {},
};
export default BottomMenu;
