import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./ToolbarPdf.module.css";
import "./ToolbarPdf.css";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import { IconButton, Button, Menu, MenuItem } from "@material-ui/core";
import { findClientOs } from "../../Functions/editorShortcut";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
const ToolbarPdf = (props) => {
  const [elmentZoomToolbar, setElmentZoomToolbar] = useState(null);
  const isOpenElmentZoomToolbar = Boolean(elmentZoomToolbar);
  const defaultColor = "rgba(0, 0, 0, 0.54)";

  const CtrlKey = () => {
    let isMacOs = findClientOs() == "MacOS";
    return isMacOs ? "⌘" : "Ctrl";
  };
  const handleClickElmentForZoomToolbar = (event) => {
    setElmentZoomToolbar(event.currentTarget);
  };
  const handleCloseElmentForZoomToolbar = () => {
    setElmentZoomToolbar(null);
    if (props.zoom == 0) {
      props.handleZoom(30);
    }
  };
  return (
    <div className={classes.Toolbar_Panel_Box}>
      <IconButton
        title={"ReGenerate PDF "}
        className={classes.ToolbarButton}
        onClick={() => {
          props.handleReGeneratePdf();
        }}
        style={{ color: defaultColor }}
      >
        <RestorePageIcon />
      </IconButton>
      {props.showPdf && (
        <>
          <div className={classes.ToolbarPages}>
            <div
              className={classes.ToolbarPreviewAndNext}
              title="Previous Page"
              onClick={() => {
                props.ChangeCurrentPage((number) =>
                  number == null ? 1 : number - 1 > 0 ? number - 1 : 1
                );
              }}
            >
              <KeyboardArrowUpIcon style={{ width: "20px" }} />
            </div>
            <div
              className={classes.ToolbarPreviewAndNext}
              title="Next Page"
              onClick={() => {
                props.ChangeCurrentPage((number) =>
                  number == null
                    ? 1
                    : number + 1 > props.PageNUmbers
                    ? props.PageNUmbers
                    : number + 1
                );
              }}
            >
              <KeyboardArrowUpIcon
                style={{ transform: "rotate(180deg)", width: "20px" }}
              />
            </div>
            <input
              type="number"
              min={0}
              step={1}
              max={props.PageNUmbers}
              value={props.currentPageNO}
              className={classes.ToolbarInputPageNumber}
              onChange={(e) => {
                let number =
                  e.target.value.trim() == "" ? null : Number(e.target.value);
                if (
                  (number != NaN &&
                    number != undefined &&
                    number > 0 &&
                    number <= props.PageNUmbers) ||
                  number == null
                ) {
                  props.ChangeCurrentPage(number);
                }
              }}
            />{" "}
            / {props.PageNUmbers}
          </div>

          <div className={classes.ToolbarZoom}>
            <div
              className={classes.ToolbarZoomInAndZoomOUt}
              title={"Zoom out \n " + CtrlKey() + " + -"}
              onClick={() => {
                props.handleZoom((prev) => (prev - 5 < 10 ? 10 : prev - 5));
              }}
            >
              <RemoveIcon style={{ width: "16px" }} />
            </div>
            <div
              className={classes.ToolbarZoomInAndZoomOUt}
              title={"Zoom in \n " + CtrlKey() + " + +"}
              onClick={() => {
                props.handleZoom((prev) => prev + 5);
              }}
            >
              <AddIcon style={{ width: "16px" }} />
            </div>
            <Button
              title="Zoom"
              aria-controls={"basic-menu"}
              aria-haspopup="true"
              aria-expanded={"true"}
              onClick={handleClickElmentForZoomToolbar}
              style={{
                color: "#515050",
                textTransform: "none",
                // width: '100px',
              }}
            >
              {props.zoom}% &nbsp;
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              style={{ width: "100%" }}
              anchorEl={elmentZoomToolbar}
              open={isOpenElmentZoomToolbar}
              onClose={handleCloseElmentForZoomToolbar}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                style={{
                  cursor: "unset",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <input
                  type="number"
                  min={0}
                  step={5}
                  value={props.zoom}
                  className={classes.ToolbarInputZoom}
                  onChange={(e) => {
                    let number =
                      e.target.value.trim() == ""
                        ? null
                        : Number(e.target.value);
                    if (number != NaN && number != undefined && number > 0) {
                      props.handleZoom(number);
                    }
                  }}
                />
                %
              </MenuItem>
              <hr></hr>
              <MenuItem
                style={{
                  cursor: "unset",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div className={classes.ToolbarZoomToPanel}>
                  <div
                    className={
                      classes.ToolbarZoomItem +
                      " " +
                      classes.ToolbarZoomShortCut
                    }
                    onClick={() => {
                      props.handleZoom((prev) => prev + 5);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    <div>Zoom in</div>
                    <div>{CtrlKey()}&nbsp; +</div>
                  </div>
                  <div
                    className={
                      classes.ToolbarZoomItem +
                      " " +
                      classes.ToolbarZoomShortCut
                    }
                    onClick={() => {
                      props.handleZoom((prev) =>
                        prev - 5 < 10 ? prev : prev - 5
                      );
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    <div>Zoom out</div>
                    <div>{CtrlKey()}&nbsp; -</div>
                  </div>
                </div>
              </MenuItem>
              <hr></hr>
              <MenuItem
                style={{
                  cursor: "unset",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div className={classes.ToolbarZoomToPanel}>
                  <div
                    className={
                      classes.ToolbarZoomTitle + " " + classes.ToolbarZoomItem
                    }
                    style={{
                      cursor: "unset",
                    }}
                  >
                    Zoom to
                  </div>
                  <div
                    className={classes.ToolbarZoomItem}
                    onClick={() => {
                      props.handleZoom(50);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    50%
                  </div>
                  <div
                    className={classes.ToolbarZoomItem}
                    onClick={() => {
                      props.handleZoom(75);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    75%
                  </div>
                  <div
                    className={classes.ToolbarZoomItem}
                    onClick={() => {
                      props.handleZoom(100);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    100%
                  </div>
                  <div
                    className={classes.ToolbarZoomItem}
                    onClick={() => {
                      props.handleZoom(150);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    200%
                  </div>
                  <div
                    className={classes.ToolbarZoomItem}
                    onClick={() => {
                      props.handleZoom(200);
                      handleCloseElmentForZoomToolbar();
                    }}
                  >
                    150%
                  </div>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </>
      )}
    </div>
  );
};
export default ToolbarPdf;

ToolbarPdf.propTypes = {
  handleReGeneratePdf: PropTypes.func,
  handleZoom: PropTypes.func,
  zoom: PropTypes.number,
  PageNUmbers: PropTypes.number,
  showPdf: PropTypes.bool,
  currentPageNO: PropTypes.number,
  ChangeCurrentPage: PropTypes.func,
};
ToolbarPdf.defaultProps = {
  handleReGeneratePdf: () => {},
  handleZoom: () => {},
  showPdf: false,
  zoom: 100,
  PageNUmbers: 1,
  currentPageNO: 1,
  ChangeCurrentPage: () => {},
};
