import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import styles from "./ShortcutDialog.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { OsControlKey } from "../../../Functions/editorShortcut.js";
import "../../../components/LiboBerryCss/liboBerry.css";
const ShortcutDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="shortcut-dialog-title"
      className={styles.ShortcutDialog}
      disableBackdropClick={true}
      disableEnforceFocus
      hideBackdrop
      disableScrollLock
    >
      <DialogTitle className={styles.shortcuttitle} id="shortcut-dialog-title">
        <div className={styles.titletext}>Keyboard shortcuts</div>
        <IconButton onClick={onClose} className={styles.CloseIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={"liboScroll"} dividers>
        <ul>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>Save your work</div>
              <div className={styles.shortcutpackage1}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>S</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                <b>Bold</b>
              </div>
              <div className={styles.shortcutpackage2}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>B</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                <i>Italic</i>
              </div>
              <div className={styles.shortcutpackage3}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>I</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                <u>Underline</u>
              </div>
              <div className={styles.shortcutpackage4}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>L</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                Strike <strike>through</strike>
              </div>
              <div className={styles.shortcutpackage5}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>D</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                Super<sup>script</sup>
              </div>
              <div className={styles.shortcutpackage6}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>Shift</span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>+</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>
                Sub<sub>script</sub>
              </div>
              <div className={styles.shortcutpackage7}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>=</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>Insert formula</div>
              <div className={styles.shortcutpackage8}>
                <span
                  className={styles["shortcut-key"]}
                  styles={{ minWidth: "36px" }}
                >
                  {OsControlKey("Alt")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>=</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>Insert citations</div>
              <div className={styles.shortcutpackage9}>
                <span className={styles["shortcut-key"]}>
                  {OsControlKey("Ctrl")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>Shift</span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>/</span>
              </div>
            </div>
          </li>
          <li className={styles.listTypeNone}>
            <div className={styles.container}>
              <div className={styles.shortcutName}>Liboberry AI Assistents</div>
              <div className={styles.shortcutpackage8}>
                <span
                  className={styles["shortcut-key"]}
                  styles={{ minWidth: "36px" }}
                >
                  {OsControlKey("Alt")}
                </span>
                <span className={styles["shortcut-separator"]}>+</span>
                <span className={styles["shortcut-key"]}>A</span>
              </div>
            </div>
          </li>
        </ul>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ShortcutDialog;
