import { keywords } from "./LaTexKeyWords.js";

export const countLatexWord = (latexText) => {
  const latexRegex = /\\[a-zA-Z]+\{[^}]*\}|\\[a-zA-Z]+|\$[^\$]*\$/g;
  const paragraphs = latexText.split(/\n\s*\n/);
  // Clean each paragraph by removing LaTeX commands
  const cleanedParagraphs = paragraphs.map((paragraph) => {
    return paragraph.replace(latexRegex, "").trim();
  });

  var list = cleanedParagraphs.filter((p) => p.length > 0);
  // Return cleaned paragraphs as an array
  return list.length > 0
    ? list
        .join(" ")
        .split(" ")
        .filter(
          (s) =>
            s != null &&
            s.trim() != "" &&
            s.trim() != "\n" &&
            s.trim() != "\r" &&
            s.trim() != "\n\r" &&
            s != " " &&
            s.length > 0
        ).length
    : 0; // Filter out any empty paragraphs
};
export const getLaTeXSelection = (latexText, from, to) => {
  // Regular expression to match LaTeX commands and environments
  const latexRegex = /\\[a-zA-Z]+\{[^}]*\}|\\[a-zA-Z]+|\$[^\$]*\$/g;
  let newValue = "";
  try {
    newValue = latexText
      .substring(from, to)
      .replace(latexRegex, "")
      .trim()
      .toLowerCase()
      .replace(latexRegex, "")
      .trim();
  } catch {}
  return newValue;
};
export const FindLaTeXParagraph = (latexText, index, untilIndex = false) => {
  // Regular expression to match LaTeX commands and environments
  const latexRegex = /\\[a-zA-Z]+\{[^}]*\}|\\[a-zA-Z]+|\$[^\$]*\$/g;

  let start_indexP = latexText.substring(0, index).lastIndexOf("\n");
  let end_indexP = latexText.indexOf("\n", index);

  let newValue = latexText
    .substring(start_indexP, untilIndex ? index : end_indexP)
    .replace(latexRegex, "")
    .trim()
    .toLowerCase();
  if (untilIndex) {
    return newValue.replace(latexRegex, "").trim();
  }
  // Split the LaTeX text into paragraphs
  const paragraphs = latexText.split(/\n\s*\n/);
  // Clean each paragraph by removing LaTeX commands
  const cleanedParagraphs = paragraphs.map((paragraph) => {
    return paragraph.replace(latexRegex, "").trim();
  });

  var list = cleanedParagraphs.filter(
    (p) => p.toLowerCase().includes(newValue) && p.length > 0
  );
  // Return cleaned paragraphs as an array
  return list.length > 0
    ? untilIndex
      ? newValue.replace(latexRegex, "").trim()
      : list[0]
    : ""; // Filter out any empty paragraphs
};
export const myCompletions = (context) => {
  let word = context.matchBefore(/\\\w*/);
  if (!word) {
    word = context.matchBefore(/\w*/);
  }
  if (!word || (word.from == word.to && !context.explicit)) return null;
  return {
    from: word.from,
    options: keywords.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }),
  };
};
export const createLaTeXTable = (x, y) => {
  let row = Array(x)
    .fill(0)
    .map((a, i) => {
      return "l";
    })
    .join("");

  let table = "\\begin{table} \n";
  table += "  \\centering \n";
  table += "  \\begin{tabular}{" + row + "} \n  ";

  // let colsize = (100 / x).toFixed(2);
  table += Array(y)
    .fill(0)
    .map((a, i) => {
      return `${Array(x)
        .fill(0)
        .map((b, j) => " ")
        .join("&")}`;
    })
    .join(" \\\\ \n  ");
  table += " \\\\ \n  \\end{tabular} \n";
  table += "  \\caption{Caption} \n";
  table += "  \\label{tab:my_label} \n";
  table += "\\end{table} \n";
  return table;
};
export const latexCommon = (common, callBackfunction = null) => {
  let result = "";
  if (common == "bold") {
    //ctrl+b  : Bold
    if (callBackfunction) callBackfunction();
    result = "\\textbf";
  } else if (common == "italic") {
    //ctrl+i  : Italic
    if (callBackfunction) callBackfunction();
    result = "\\textit";
  } else if (common == "underline") {
    //ctrl+u  : underline
    if (callBackfunction) callBackfunction();
    result = "\\underline";
  } else if (common == "strikeThrough") {
    //ctrl+d  : strikeThrough
    if (callBackfunction) callBackfunction();
    result = "\\sout";
  } else if (common == "superscript") {
    //ctrl+shift+'+'  : superscript
    if (callBackfunction) callBackfunction();
    result = "\\textsuperscript";
  } else if (common == "subscript") {
    //ctrl+'='  : subscript
    if (callBackfunction) callBackfunction();
    result = "\\textsubscript";
  } else if (common == "link") {
    //ctrl+'='  : subscript
    if (callBackfunction) callBackfunction();
    result = "\\href{}";
  } else if (common == "listOrder") {
    //ctrl+'='  : subscript
    if (callBackfunction) callBackfunction();
    result = "\\begin{itemize}\n  \\item \n\\end{itemize}\n";
  } else if (common == "listNumber") {
    //ctrl+'='  : subscript
    if (callBackfunction) callBackfunction();
    result = "\\begin{enumerate}\n  \\item \n\\end{enumerate}\n";
  }

  return result;
};
