export const headings = [
  { title: "Title", value: "title", latexCode: "\\title", width: "20px" },
  { title: "Section", value: "section", latexCode: "\\section", width: "15px" },
  {
    title: "SubSection",
    value: "subsection",
    latexCode: "\\subsection",
    width: "10px",
  },
  {
    title: "SubSubSection",
    value: "subsubsection",
    latexCode: "\\subsubsection",
    width: "5px",
  },
  {
    title: "Abstract",
    value: "abstract",
    latexCode: "\\abstract",
    width: "20px",
  },
  {
    title: "Keywords",
    value: "keywords",
    latexCode: "\\textbf{Keywords}",
    width: "20px",
  },
  { title: "Body Text", value: "div", latexCode: "", width: "0px" },
];
