import React, { useEffect, useRef, useState } from "react";
import PropTypes, { number } from "prop-types";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./PdfPreview.css";
import { findClientOs } from "../../Functions/editorShortcut";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfPreview(props) {
  const [numPages, setNumPages] = useState(null);
  const [wheelzoom, setwheelzoom] = useState(props.zoom);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState("");
  const pdfRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  // Use the local path to the PDF file
  const pdfFile = props.file;

  useEffect(() => {
    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", zoomCheck);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", zoomCheck);
    };
  }, []);
  useEffect(() => {
    if (pdfRef.current) {
      pdfRef.current.addEventListener("scroll", updateCurrentPage);
    }
    return () => {
      if (pdfRef.current) {
        pdfRef.current.removeEventListener("scroll", updateCurrentPage);
      }
    };
  }, []);
  useEffect(() => {
    const pdfElement = pdfRef.current;
    pdfElement.addEventListener("mouseup", props.handleTextSelection);
    return () => {
      pdfElement.removeEventListener("mouseup", props.handleTextSelection);
    };
  }, [props.handleTextSelection]);
  useEffect(() => {
    setwheelzoom(props.zoom);
    updateCurrentPage();
  }, [props.zoom]);
  useEffect(() => {
    if (currentPage != props.DisplayPageNO) {
      props.ChangeDisplayPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage != props.DisplayPageNO) {
      ChangeScrollPosition(props.DisplayPageNO);
    }
  }, [props.DisplayPageNO]);
  useEffect(() => {
    props.setZoom(wheelzoom);
  }, [wheelzoom]);

  const onDocumentLoadSuccess = async (pdf) => {
    setNumPages(pdf.numPages);
    setError(null);
    setDebugInfo(`Successfully loaded PDF with ${pdf.numPages} pages`);
    props.handlePdfPageNumbes(pdf.numPages);
    props.setLoadPdf(false);
    updateCurrentPage();
    setTimeout(() => {
      if (pdfRef.current) {
        props.setPdfText(pdfRef.current.innerText);
      }
    }, 1000);
  };
  // function onDocumentLoadSuccess({ numPages }) {
  //   // console.log("Number of pages:", numPages);
  //   setNumPages(numPages);
  //   setError(null);
  //   setDebugInfo(`Successfully loaded PDF with ${numPages} pages`);
  //   props.handlePdfPageNumbes(numPages);
  //   props.setLoadPdf(false);
  //   updateCurrentPage();
  //   const text = await extractTextFromPdf(pdf);
  //   setPdfText(text);
  //   setEditorContent(text);
  // }

  const ChangeScrollPosition = (pdfnumber) => {
    if (pdfRef.current) {
      const pages = pdfRef.current.querySelectorAll(".react-pdf__Page");
      let currentPageIndex = 0;

      pages.forEach((page, index) => {
        if (index + 1 == pdfnumber) {
          page.scrollIntoView({ behavior: "smooth", block: "center" });
          return;
        }
      });
      if (currentPageIndex > 0) {
        setCurrentPage(currentPageIndex);
      }
    }
  };
  // Update the current page based on scroll position
  const updateCurrentPage = () => {
    if (pdfRef.current) {
      const pages = pdfRef.current.querySelectorAll(".react-pdf__Page");
      let currentPageIndex = 0;

      pages.forEach((page, index) => {
        const rect = page.getBoundingClientRect();
        if (rect.top + 100 >= 0 && rect.top + 100 < window.innerHeight) {
          currentPageIndex = index + 1; // +1 because index is zero-based
          return;
        }
      });
      if (currentPageIndex > 0) {
        setCurrentPage(currentPageIndex);
      }
    }
  };

  function onDocumentLoadError(error) {
    console.error("Error loading PDF:", error);
    setError(
      "Failed to load PDF. Please check if the file exists and is accessible."
    );
    console.log(`Error loading PDF: ${JSON.stringify(error)}`, error);
    setDebugInfo(`Error loading PDF: ${JSON.stringify(error)}`);
    props.setLoadPdf(false);
  }
  const extractTextFromPdf = async (pdf) => {
    const textContent = await pdf.allXfaHtml;
    console.log("textContent", textContent);
    const x = await pdf.loadingTask;
    console.log("x", x);
    return textContent.items.map((item) => item.str).join(" ");
  };

  const handleWheel = (event) => {
    let controlKey = findClientOs() == "MacOS" ? event.metaKey : event.ctrlKey;
    let target = event.target;

    if (!controlKey) {
      return;
    }
    while (target != null && target.id != "pdfPreviewPanelBox") {
      target = target.parentNode;
    }
    if (target != null && target.id == "pdfPreviewPanelBox") {
      event.preventDefault();

      if (event.deltaY < 0) {
        props.setZoom((pervzoom) => pervzoom + 5); // Zoom in
      } else {
        props.setZoom((pervzoom) =>
          pervzoom - 5 > 10 ? pervzoom - 5 : pervzoom
        ); // Zoom out
      }
    }
  };
  const zoomCheck = (event) => {
    let controlKey = findClientOs() == "MacOS" ? event.metaKey : event.ctrlKey;
    if (event.which == 187 && controlKey) {
      event.preventDefault();
      props.setZoom((pervzoom) => pervzoom + 5); // Zoom in
    } else if (event.which == 189 && controlKey) {
      event.preventDefault();
      props.setZoom((pervzoom) =>
        pervzoom - 5 > 10 ? pervzoom - 5 : pervzoom
      ); // Zoom out
    }
  };

  return (
    <div ref={pdfRef} className={"PdfPreviewPanel"}>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => {
            return (
              <Page
                key={`page_${page}`}
                pageNumber={page}
                scale={props.zoom / 100}
              />
            );
          })}
      </Document>
    </div>
  );
}

PdfPreview.propTypes = {
  file: PropTypes.string.isRequired,
  setLoadPdf: PropTypes.func,
  handlePdfPageNumbes: PropTypes.func,
  ChangeDisplayPage: PropTypes.func,
  zoom: PropTypes.number,
  setZoom: PropTypes.func,
  DisplayPageNO: PropTypes.number,
  setPdfText: PropTypes.number,
  handleTextSelection: PropTypes.func,
};
PdfPreview.defaultProps = {
  setLoadPdf: () => {},
  zoom: 100,
  handlePdfPageNumbes: () => {},
  ChangeDisplayPage: () => {},
  DisplayPageNO: 1,
  setZoom: () => {},
  setPdfText: () => {},
  handleTextSelection: () => {},
};
export default PdfPreview;
