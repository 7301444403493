import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { toast } from "react-toastify";
import classes from "./Authors.module.css";
import ExtentionIcon from "../../../components/ExtentionIcon/ExtentionIcon.js";
import { updateProject } from "../../../Requests/Requests.js";
import { Fade } from "react-reveal";
import DeleteIcon from "@material-ui/icons/Delete";
function AuthorsList(props) {
  const [authorsList, setAuthorsList] = useState([]);
  useEffect(() => {
    if (
      Array.isArray(props.AuthorsList) &&
      props.AuthorsList &&
      props.AuthorsList.length != 0
    ) {
      setAuthorsList(props.AuthorsList);
    } else {
      setAuthorsList([]);
    }
  }, [props.AuthorsList]);
  const updateProjectInfo = (value, title) => {
    updateProject(props.projectid, value)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          } else if (title == "Remove Autour") {
            toast.success(title + " successfully");
          }
        }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else {
          toast.error("Seems like there is a problem with the server");
          console.log(err);
        }
      });
  };

  let dragOn = null;
  const handleAuthorDragStart = (e) => {
    e.target.style.opacity = "0.4";
    e.effectAllowed = "move";
    e.dataTransfer.setData("text", e.target.id);
    dragOn = "authors";
  };
  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    var parentNode = e.target;
    var tr = e.target;
    while (parentNode != null) {
      console.log(parentNode.tagName);
      if (parentNode.tagName == "TBODY") {
        break;
      }
      if (parentNode.tagName == "TR") {
        tr = parentNode;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode == null || tr == null) {
      return false;
    }

    let indexDragEl = e.dataTransfer.getData("text").replace(dragOn, "");
    let indexDropEl = tr.id.replace(dragOn, "");

    if (parentNode.id == "TbodyAuthor" && dragOn == "authors") {
      let autors = authorsList;
      if (autors == null) autors = [];
      if (autors.length > 0) {
        array_move(autors, indexDragEl, indexDropEl);
        updateProjectInfo({ authors: autors }, "update Autour");
        props.setProjectInfo(autors);
      }
      // setInForm('authors', form.authors);
    }
    return false;
  };
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return;
  };
  const handleAuthorDelete = (index) => {
    let autors = authorsList;
    if (autors == null) autors = [];
    if (autors.length > 0 && index > -1) {
      autors.splice(index, 1);
      updateProjectInfo({ authors: autors }, "Remove Autour");
      props.setProjectInfo(autors);
    }
  };
  return (
    <div>
      <TableContainer
        style={{
          backgroundColor: "transparent",
          marginBottom: "20px",
        }}
      >
        <Table aria-label="caption table">
          <TableBody id={"TbodyAuthor"} className={classes.whiteTable}>
            {authorsList.length === 0 && (
              <TableRow key="emptyautour">
                <TableCell colSpan={6} align="center">
                  The authors list is empty...
                </TableCell>
              </TableRow>
            )}
            {authorsList.map((author, index) => (
              <TableRow
                key={author.email}
                id={"authors" + index}
                className={"drag-box"}
                draggable={true}
                onDragStart={(e) => {
                  handleAuthorDragStart(e);
                }}
                onDragEnd={(e) => {
                  handleDragEnd(e);
                }}
                onDragOver={(e) => {
                  handleDragOver(e);
                }}
                onDrop={(e) => {
                  handleDrop(e);
                }}
              >
                <TableCell
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                  scope="row"
                >
                  <div
                    title={author.surname + " " + author.giveName}
                    className={classes.textEllipsis}
                  >
                    {author.surname} {author.giveName}
                  </div>
                  <div title={author.email} className={classes.textEllipsis}>
                    {author.email}
                  </div>

                  {author.isCorresponding ? (
                    <div>
                      <ExtentionIcon
                        FileName={"envelope"}
                        width="20px"
                        style={{ paddingTop: "0px" }}
                      />
                      &nbsp;Corresponding Author
                    </div>
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                  align="right"
                >
                  <Fade top>
                    <IconButton
                      className={classes.LiboberryColor}
                      onClick={() => {
                        handleAuthorDelete(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Fade>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default AuthorsList;
AuthorsList.propTypes = {
  AuthorsList: PropTypes.array,
  projectid: PropTypes.number,
  setProjectInfo: PropTypes.func,
};
AuthorsList.defaultProps = {
  AuthorsList: [],
  projectid: 0,
  setProjectInfo: (authors) => {},
};
