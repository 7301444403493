import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./LaTeXToolbar.module.css";
import "./LaTeXToolbar.css";
import "../../LiboBerryCss/liboBerry.css";
import ShareIcon from "@material-ui/icons/Share";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LinkIcon from "@material-ui/icons/Link";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { headings } from "../../MSKEditor/Components/Headings";
import { ExportType } from "../../../Pages/DocumentsPage/ExportType";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import SaveIcon from "@material-ui/icons/Save";
import FunctionsIcon from "@material-ui/icons/Functions";
import GridOnIcon from "@material-ui/icons/GridOn";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { toast } from "react-toastify";
// import { TableSizes } from "./TableSizes";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import ButtonWithPopover from "../../MSKEditor/Components/ButtonWithPopover.js";
import { ImageDocumentUploadFileAllowMimeType } from "../../../Functions/FilterUploadFile.js";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Menu,
  Select,
} from "@material-ui/core";
import { getShortCut } from "../../../Functions/editorShortcut";
import { latexCommon, createLaTeXTable } from "../LiboLaTeXFunc.js";
const LaTeXToolbar = (props) => {
  const [tableSize, setTableSize] = useState([-1, -1]);
  const [elmentForPropertiesToolbar, setElmentForPropertiesToolbar] =
    React.useState(null);
  const isOpenElmentForPropertiesToolbar = Boolean(elmentForPropertiesToolbar);

  const [elmentForExportToolbar, setElmentForExportToolbar] =
    React.useState(null);
  const isOpenElmentForExportToolbar = Boolean(elmentForExportToolbar);

  const [elmentForImageToolbar, setElmentForImageToolbar] =
    React.useState(null);
  const isOpenElmentForImageToolbar = Boolean(elmentForImageToolbar);

  const activeColor = "blue";
  const defaultColor = "rgba(0, 0, 0, 0.54)";
  const [isOpenSpiliter, setIsOpenSpiliter] = useState(true);

  useEffect(() => {
    props.handleIsOpenSpiliter(isOpenSpiliter);
  }, [isOpenSpiliter]);
  const handleTableSizeChange = (i, j) => {
    setTableSize([i, j]);
  };
  const handleClickElmentForPropertiesToolbar = (event) => {
    setElmentForPropertiesToolbar(event.currentTarget);
  };
  const handleCloseElmentForPropertiesToolbar = () => {
    setElmentForPropertiesToolbar(null);
  };
  const handleClickElmentForExportToolbar = (event) => {
    setElmentForExportToolbar(event.currentTarget);
  };
  const handleCloseElmentForExportToolbar = () => {
    setElmentForExportToolbar(null);
  };
  const handleClickElmentForImageToolbar = (event) => {
    setElmentForImageToolbar(event.currentTarget);
  };
  const handleCloseElmentForImageToolbar = () => {
    setElmentForImageToolbar(null);
  };
  const handleHeadingChange = (code, title) => {
    if (code && code != "") {
      props.onChange(code, true);
      // toast.success("Inserted " + title + " Successfully!");
    }
  };
  /*
  title={
                  item.name.length >= 100
                    ? "Upload failed! The file name exceed than 100 characters. Please rename the file."
                    : ""
  
  */
  const handleFileSelect = (e) => {
    let list = [...e.target.files].filter(
      (item) =>
        ImageDocumentUploadFileAllowMimeType(item.type) &&
        item.name.length < 100
    );
    let errorlist = [...e.target.files].filter(
      (item) =>
        !ImageDocumentUploadFileAllowMimeType(item.type) ||
        item.name.length >= 100
    );
    for (let error of errorlist) {
      if (!ImageDocumentUploadFileAllowMimeType(error.type)) {
        toast.error(
          "Upload failed! The file '" + error.name + "' does not support Format"
        );
      } else {
        toast.error(
          "Upload failed! The file '" +
            error.name +
            "' exceed than 100 characters. Please rename the file."
        );
      }
    }
    if (list && list.length > 0) {
      props.onFilesUploadButtonClick(list);
    }
  };
  return (
    <div className={classes.Toolbar_Panel_Box}>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <FormControl className={classes.formControl} title="Header">
          <Select
            style={{ width: 100, marginLeft: 10, marginRight: 10 }}
            value={"div"}
            //onChange={handleHeadingChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {headings.map((heading, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleHeadingChange(heading.latexCode);
                }}
                value={heading.value}
              >
                {heading.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          title={"Bold (" + getShortCut("Bold") + ")"}
          className={classes.ToolbarButton}
          onClick={() => {
            props.onChange(latexCommon("bold"), true);
          }}
          style={{ color: defaultColor }}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          title={"Italic (" + getShortCut("Italic") + ")"}
          className={classes.ToolbarButton}
          onClick={() => {
            // setIsItalic((isItalic) => !isItalic);
            props.onChange(latexCommon("italic"), true);
          }}
          style={{ color: defaultColor }}
        >
          <FormatItalicIcon />
        </IconButton>
        <Button
          title="Properties"
          id="properties"
          name="properties"
          aria-controls={"basic-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          onClick={handleClickElmentForPropertiesToolbar}
          style={{
            color: defaultColor,
            textTransform: "none",
            // width: '100px',
          }}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="basic-menu"
          style={{ width: "100%" }}
          anchorEl={elmentForPropertiesToolbar}
          open={isOpenElmentForPropertiesToolbar}
          onClose={handleCloseElmentForPropertiesToolbar}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className={classes.MuiListItem_button}>
            <>
              <IconButton
                title={"Underline (" + getShortCut("underline") + ")"}
                className={classes.SmallToolbarButton}
                onClick={() => {
                  props.onChange(latexCommon("underline"), true);
                }}
                style={{ color: defaultColor }}
              >
                <FormatUnderlinedIcon className={classes.MuiListItem_Icon} />
              </IconButton>
              <IconButton
                title={"Strikethrough (" + getShortCut("strikeThrough") + ")"}
                className={classes.SmallToolbarButton}
                onClick={() => {
                  // setIsStrikeThrough((isStrikeThrough) => !isStrikeThrough);
                  props.onChange(latexCommon("strikeThrough"), true);
                }}
                style={{ color: defaultColor }}
              >
                <FormatStrikethroughIcon className={classes.MuiListItem_Icon} />
              </IconButton>
              <IconButton
                title={"Superscript (" + getShortCut("superscript") + ")"}
                className={classes.SmallToolbarButton}
                onClick={() => props.onChange(latexCommon("superscript"), true)}
                style={{ color: defaultColor }}
              >
                <p
                  className={classes.MuiListItem_Icon}
                  style={{ fontWeight: "bold" }}
                >
                  x
                  <sup>
                    <small>2</small>
                  </sup>
                </p>
              </IconButton>
              <IconButton
                title={"subscript (" + getShortCut("subscript") + ")"}
                className={classes.SmallToolbarButton}
                onClick={() => props.onChange(latexCommon("subscript"), true)}
                style={{ color: defaultColor }}
              >
                <p
                  className={classes.MuiListItem_Icon}
                  style={{ fontWeight: "bold" }}
                >
                  x
                  <sub>
                    <small>2</small>
                  </sub>
                </p>
              </IconButton>
              <IconButton
                title="Link"
                className={classes.SmallToolbarButton}
                onClick={() => props.onChange(latexCommon("link"), true)}
              >
                <LinkIcon className={classes.MuiListItem_Icon} />
              </IconButton>
              <IconButton
                title="Bullet List"
                className={classes.SmallToolbarButton}
                onClick={() => props.onChange(latexCommon("listOrder"))}
                style={{ color: defaultColor }}
              >
                <FormatListBulletedIcon className={classes.MuiListItem_Icon} />
              </IconButton>
              <IconButton
                title="Number List"
                className={classes.SmallToolbarButton}
                onClick={() => props.onChange(latexCommon("listNumber"))}
                style={{ color: defaultColor }}
              >
                <FormatListNumberedIcon className={classes.MuiListItem_Icon} />
              </IconButton>
            </>
          </MenuItem>
        </Menu>
        <ButtonWithPopover
          id={"table"}
          title="Add Table"
          icon={GridOnIcon}
          onPopoverOpen={() => {
            /* TODO save */
            // prevSelection = save();
          }}
          onPopoverClose={() => {
            setTableSize([-1, -1]);
          }}
          renderPopoverContent={(onClose) => {
            return (
              <div style={{ margin: 10 }}>
                <div className={classes.LiboEditorTableTitle}>
                  {tableSize[1] === -1 || tableSize[0] === -1 ? (
                    "Insert Table"
                  ) : (
                    <>
                      {tableSize[1] + 1} &times; {tableSize[0] + 1} Table
                    </>
                  )}
                </div>
                <div className={classes.LiboEditorTable}>
                  {Array(10)
                    .fill(0)
                    .map((a, i) => {
                      return (
                        <div key={i} className={classes.LiboEditorTR}>
                          {Array(10)
                            .fill(0)
                            .map((b, j) => {
                              return (
                                <div
                                  key={i + "_" + j}
                                  className={[
                                    classes.LiboEditorTD,
                                    ...(i <= tableSize[0] && j <= tableSize[1]
                                      ? [classes.active]
                                      : []),
                                  ].join(" ")}
                                  onMouseOver={() =>
                                    handleTableSizeChange(i, j)
                                  }
                                  onClick={() => {
                                    let table = createLaTeXTable(
                                      tableSize[1] + 1,
                                      tableSize[0] + 1
                                    );

                                    props.onChange(table);
                                    onClose();
                                  }}
                                />
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          }}
        />
        <IconButton
          title="Image"
          id="imageProject"
          name="imageProject"
          aria-controls={"basic-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          onClick={handleClickElmentForImageToolbar}
          style={{
            color: defaultColor,
            textTransform: "none",
            // width: '100px',
          }}
        >
          <PhotoLibraryIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          style={{ width: "100%" }}
          anchorEl={elmentForImageToolbar}
          open={isOpenElmentForImageToolbar}
          onClose={handleCloseElmentForImageToolbar}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem style={{ width: "100%", padding: "0px" }}>
            <Button
              variant="contained"
              component="label"
              style={{
                textTransform: "none",
                padding: "13px 20px",
                width: "100%",
                background: "no-repeat",
                border: "0px none transparent",
                boxShadow: "none",
                display: "flex",
                justifyContent: "space-between",
                color: "#eb3449",
                fontWeight: "500",
              }}
            >
              Upload Files
              <input
                type="file"
                accept="image/*"
                hidden
                multiple
                onChange={handleFileSelect}
              />
              <CloudUploadIcon />
            </Button>
          </MenuItem>
          <hr></hr>
          <MenuItem
            style={{
              width: "320px",
              cursor: "default",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              maxHeight: "230px",
              overflowY: "auto",
            }}
            className={"liboScroll"}
          >
            {props.projectFiles.length === 0 && (
              <span style={{ color: "#aea8a8" }}>No images ...</span>
            )}
            {props.projectFiles
              .sort((a, b) => b.id - a.id)
              .map((img) => (
                <div
                  // draggable="true"
                  key={img.id}
                  title={img.name + "\nClick to copy the image link!"}
                  style={{
                    backgroundImage: `url(${img.file})`,
                  }}
                  className={classes.image}
                  onClick={() => {
                    props.onAddImage(img.id + "," + img.file);
                    handleCloseElmentForImageToolbar();
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onProjectFileDelete(img.id);
                    }}
                    className={classes.imageDeleteIcon}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              ))}
          </MenuItem>
        </Menu>
        <IconButton
          title={"Insert Formula (" + getShortCut("InsertFormula") + ")"}
          id="insertFormula"
          name="insertFormula"
          onClick={() => {
            props.handleClickOpenFormulaModel();
          }}
          style={{ color: defaultColor }}
        >
          <FunctionsIcon />
        </IconButton>
        <IconButton
          title="Undo"
          className={classes.ToolbarButton}
          onClick={props.Undo}
        >
          <UndoIcon />
        </IconButton>
        <IconButton
          title="redo"
          className={classes.ToolbarButton}
          onClick={props.Redo}
        >
          <RedoIcon />
        </IconButton>
        <IconButton
          title={"Share project"}
          className={classes.ToolbarButton}
          onClick={() => props.handleOpenShareDialog(props.projectId)}
        >
          <ShareIcon style={{ color: defaultColor }} />
        </IconButton>
        <IconButton
          title="Export Project"
          id="exprtProject"
          name="exprtProject"
          aria-controls={"basic-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          onClick={handleClickElmentForExportToolbar}
          style={{
            color: defaultColor,
            textTransform: "none",
            // width: '100px',
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          style={{ width: "100%" }}
          anchorEl={elmentForExportToolbar}
          open={isOpenElmentForExportToolbar}
          onClose={handleCloseElmentForExportToolbar}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {ExportType.map((item, index) => (
            <MenuItem
              key={index}
              style={{ width: "100%" }}
              onClick={() => {
                props.ExportLiboberryGenerator(item.value);
                handleCloseElmentForExportToolbar();
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
        <IconButton
          title={"Save (" + getShortCut("Save") + ")"}
          className={classes.ToolbarButton}
          onClick={() => {
            props.handleOnSaveClick(true, true, false);
          }}
        >
          <SaveIcon />
        </IconButton>
      </div>
      <div className="Spliter-btn">
        <IconButton
          title={isOpenSpiliter ? "Hide" : "Pdf Preview"}
          onClick={() => {
            setIsOpenSpiliter(!isOpenSpiliter);
            if (!isOpenSpiliter) {
              props.handleOnSaveClick(false, true, true);
            }
          }}
        >
          {isOpenSpiliter && (
            <>
              <SystemUpdateAltIcon
                style={{
                  transform: "rotate(270deg)",
                  color: "rgb(123 107 201)",
                }}
              />
            </>
          )}
          {!isOpenSpiliter && (
            <>
              <VerticalSplitIcon style={{ color: "rgb(123 107 201)" }} />
            </>
          )}
        </IconButton>
      </div>
    </div>
  );
};
export default LaTeXToolbar;

LaTeXToolbar.propTypes = {
  onChange: PropTypes.func,
  Undo: PropTypes.func,
  Redo: PropTypes.func,
  projectId: PropTypes.number,
  ExportLiboberryGenerator: PropTypes.func,
  handleOnSaveClick: PropTypes.func,
  handleOpenShareDialog: PropTypes.func,
  handleClickOpenFormulaModel: PropTypes.func,
  handleIsOpenSpiliter: PropTypes.func,
  onAddImage: PropTypes.func,
  projectFiles: PropTypes.array,
  onProjectFileDelete: PropTypes.func,
  onFilesUploadButtonClick: PropTypes.func,
};
LaTeXToolbar.defaultProps = {
  onChange: () => {},
  Undo: () => {},
  Redo: () => {},
  projectId: 0,
  ExportLiboberryGenerator: () => {},
  handleOnSaveClick: () => {},
  handleOpenShareDialog: () => {},
  handleClickOpenFormulaModel: () => {},
  handleIsOpenSpiliter: () => {},
  onAddImage: () => {},
  projectFiles: [],
  onProjectFileDelete: () => {},
  onFilesUploadButtonClick: () => {},
};
