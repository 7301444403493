import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { feeddbackResults } from "../feeddbackResults.js";
import classes from "../FeedBack.module.css";
import "../../../components/LiboBerryCss/liboBerry.css";

function FeedbackDialog(props) {
  const {
    open,
    onClose,
    selectTopic,
    setSelectTopic,
    content,
    setContent,
    customTopic,
    setCustomTopic,
    validationFeedBack,
    sendFeedback,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="feedback-dialog-title"
      // BackdropProps={{
      //   // pointerEvents: "none",
      //   style: { backgroundColor: "transparent" },
      // }}
      className={classes.FeedbackDialog}
      disableBackdropClick={true}
      disableEnforceFocus
      hideBackdrop
      disableScrollLock
    >
      <DialogTitle id="feedback-dialog-title" className={classes.dialogTitle}>
        Question / Feedback
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={"liboScroll"}>
        <div>
          <div>
            <h3>Topic :</h3>
            <div className={classes.FeedBack_Topic_Panel}>
              {feeddbackResults.map((topic, index) => (
                <div
                  className={
                    selectTopic == topic.title
                      ? classes.FeedBack_topic_Item_Select
                      : classes.FeedBack_topic_Item
                  }
                  onClick={() => {
                    setSelectTopic(topic.title);
                  }}
                  key={`topic${topic.key}`}
                >
                  {topic.title}
                </div>
              ))}
            </div>
          </div>
          {selectTopic == "Others" && (
            <div className={"libo_Field_Padding"}>
              <TextField
                InputProps={{
                  className: "inputFont",
                }}
                id="txtCustomTopic"
                label="Custom Topic"
                variant="outlined"
                style={{ width: "100%" }}
                disabled={false}
                size="small"
                defaultValue={customTopic}
                onChange={(e) => {
                  setCustomTopic(e.target.value.trim());
                }}
              />
            </div>
          )}
          <div className={"libo_Field_Padding"}>
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              id="txtFeedbackContent"
              label="Message"
              variant="outlined"
              style={{ width: "100%" }}
              disabled={false}
              defaultValue={content}
              multiline
              size="small"
              minRows={4}
              onChange={(e) => {
                setContent(e.target.value.trim());
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (validationFeedBack) {
              sendFeedback();
            }
          }}
          className={validationFeedBack ? "liboBtnColor" : "liboBtnDisable"}
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectTopic: PropTypes.string.isRequired,
  setSelectTopic: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  customTopic: PropTypes.string.isRequired,
  setCustomTopic: PropTypes.func.isRequired,
  validationFeedBack: PropTypes.bool.isRequired,
  sendFeedback: PropTypes.func.isRequired,
};

export default FeedbackDialog;
