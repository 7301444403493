import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./AIAsk.module.css";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import "../../LiboBerryCss/liboBerry.css";
import EditIcon from "@material-ui/icons/Edit";
import LabelItem from "../../Tags/LabelItem";
import { CircularProgress } from "@material-ui/core";
const AIAsk = (props) => {
  const [askValue, setAskValue] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const txtAsk = useRef(null);
  const historyPanel = useRef(null);

  const setcurserIntoInput = () => {
    txtAsk.current.focus();
  };
  const replayInnerTextData = () => {
    let div = document.createElement("div");
    div.innerHTML = props.replayItem ? props.replayItem.text : "";
    return div.innerText.trim();
  };
  useEffect(() => {
    if (props.historyTarget) {
      if (historyPanel && !historyPanel.current.contains(props.historyTarget)) {
        setShowHistory(false);
      }
      props.setHistoryTarget(null);
    }
  }, [props.historyTarget]);
  return (
    <div ref={historyPanel} className={classes.AiAskPanel}>
      {/* <div className={classes.AiAsk_Selection_Box}>{props.SelectionStatus}</div> */}
      {/* <div
        title="Close (Esc)"
        onClick={() => {
          props.Close();
        }}
        className={classes.AiAsk_Close}
      >
        <div>&times;</div>
      </div> */}
      {props.replayItem && (
        <div
          onClick={() => {
            if (props.replayItem) {
              let _id = props.replayItem.number + "_" + props.replayItem.type;
              let doc = document.getElementById(_id);
              if (doc) {
                doc.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }
          }}
          title={replayInnerTextData()}
          className={classes.AiAsk_Selection_Box}
        >
          {replayInnerTextData()}
          <div
            onClick={() => {
              props.removeReplayItem();
            }}
            className={classes.AiAsk_Close}
          >
            <div>&times;</div>
          </div>
        </div>
      )}
      <TextField
        inputRef={txtAsk}
        // InputLabelProps={{ shrink: true }}
        placeholder="Message Libo AI"
        // variant="outlined"
        size="small"
        disabled={false}
        style={{ width: "100%" }}
        InputProps={{
          className: "inputFont inputFontSize0_9em",
          endAdornment: (
            <InputAdornment position="end">
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (askValue != null && askValue.trim() != "") {
                    if (!props.disable) {
                      if (props.AiAssistentClick(askValue)) {
                        setAskValue("");
                      }
                    }
                  }
                  setcurserIntoInput();
                }}
              >
                {!props.disable ? (
                  <ArrowUpwardIcon
                    style={{
                      backgroundColor: "#51cc84",
                      borderRadius: "100%",
                      padding: "3px",
                      color: "white",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                )}
              </div>
            </InputAdornment>
          ),
        }}
        value={askValue}
        onKeyDown={(e) => {
          if (e.which == 27) {
            //escape
            setShowHistory(false);
          }
          if (e.which == 13 && askValue != null && askValue.trim() != "") {
            if (!props.disable) {
              if (props.AiAssistentClick(askValue)) {
                setAskValue("");
              }
            }
            e.stopPropagation();
            e.preventDefault();
            setcurserIntoInput();
          }
        }}
        onKeyUp={(e) => {
          if (e.which == 13) e.preventDefault();
        }}
        onChange={(e) => {
          setAskValue(e.target.value);
          if (e.target.value != null && e.target.value != "") {
            setShowHistory(true);
          } else {
            setShowHistory(false);
          }
        }}
      ></TextField>
      {askValue != "" &&
        showHistory &&
        props.bookmarkList &&
        props.bookmarkList.filter(
          (s) =>
            s.prompt_text.includes(askValue) ||
            s.prompt_title.includes(askValue)
        ).length > 0 && (
          <div className={classes.AskBookmarkList + " liboScroll"}>
            {props.bookmarkList
              .filter(
                (s) =>
                  s.prompt_text.includes(askValue) ||
                  s.prompt_title.includes(askValue)
              )
              .sort((a, b) => a.prompt_text.localeCompare(b.prompt_text))
              .map((bookmark) => {
                return (
                  <div className={classes.AskBookmarkList_Item}>
                    <div className={classes.AskBookmarkList_ContentPanel}>
                      <div
                        className={classes.AskBookmarkList_Title}
                        title={bookmark.prompt_title}
                        onClick={() => {
                          setAskValue(bookmark.prompt_text);
                          setShowHistory(false);
                        }}
                      >
                        {bookmark.prompt_title}
                      </div>
                      <div
                        className={classes.AskBookmarkList_Text}
                        title={bookmark.prompt_text}
                        onClick={() => {
                          setAskValue(bookmark.prompt_text);
                          setShowHistory(false);
                        }}
                      >
                        {bookmark.prompt_text}
                      </div>
                      <div
                        onClick={() => {
                          setAskValue(bookmark.prompt_text);
                          setShowHistory(false);
                        }}
                        className={classes.AskBookmarkList_labelPanel}
                      >
                        {bookmark.tags != null &&
                          bookmark.tags.length > 0 &&
                          bookmark.tags.map((tagId, index) => {
                            if (
                              props.promptLabels != null &&
                              props.promptLabels.length > 0
                            ) {
                              let labels = [
                                ...props.promptLabels.filter(
                                  (s) => s.id == tagId
                                ),
                              ];
                              if (labels.length > 0) {
                                let label = labels.pop();
                                console.log(labels, label);
                                return (
                                  <>
                                    <LabelItem
                                      curserIsPointer={false}
                                      hasCount={false}
                                      key={
                                        (tagId + " " + bookmark.id,
                                        +" " + index)
                                      }
                                      name={label.tag_name}
                                      color={label.color}
                                    />
                                  </>
                                );
                              }
                            }
                          })}
                        {(bookmark.tags == null ||
                          bookmark.tags.length == 0) && (
                          <>
                            <LabelItem
                              curserIsPointer={false}
                              hasCount={false}
                              name={"Undefined Label"}
                              color={"#b9b3b3"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.AskBookmarkList_btn_Item}>
                      <div
                        style={{ cursor: "pointer", padding: "0px 5px" }}
                        title={"Edit"}
                        onClick={() => {
                          props.EditBookMark({
                            bookmarkId: bookmark.id,
                            text: bookmark.prompt_text,
                            bookmarkTitle: bookmark.prompt_title,
                            bookmarkTags: bookmark.tags,
                          });
                        }}
                      >
                        <EditIcon
                          title="Edit"
                          style={{
                            color: "rgba(38, 222, 115, 0.851)",
                            borderRadius: "50%",
                            width: "16px",
                            cursor: "pointer",
                          }}
                          className={classes.rightIcon}
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer", padding: "0px 5px" }}
                        title={"Remove"}
                        onClick={() => {
                          props.removeBookMark(bookmark.id);
                        }}
                      >
                        <DeleteForeverIcon
                          style={{
                            color: "tomato",
                            width: "16px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
    </div>
  );
};

export default AIAsk;
AIAsk.propTypes = {
  SelectionStatus: PropTypes.string,
  Close: PropTypes.func,
  RemoveAiPanel: PropTypes.func,
  AiAssistentClick: PropTypes.func,
  setAiCaption: PropTypes.func,
  setAiType: PropTypes.func,
  setAiOpenPanel: PropTypes.func,
  setAiTitle: PropTypes.func,
  disable: PropTypes.bool,
  replayItem: PropTypes.object,
  removeReplayItem: PropTypes.func,
  bookmarkList: PropTypes.array,
  historyTarget: PropTypes.object,
  setHistoryTarget: PropTypes.func,
  removeBookMark: PropTypes.func,
  EditBookMark: PropTypes.func,
  promptLabels: PropTypes.array,
};
AIAsk.defaultProps = {
  SelectionStatus: "Document",
  Close: () => {},
  RemoveAiPanel: () => {},
  setAiTitle: () => {},
  AiAssistentClick: () => {},
  setAiCaption: () => {},
  setAiType: () => {},
  setAiOpenPanel: () => {},
  disable: false,
  replayItem: null,
  removeReplayItem: () => {},
  bookmarkList: [],
  historyTarget: null,
  setHistoryTarget: () => {},
  removeBookMark: () => {},
  EditBookMark: () => {},
  promptLabels: [],
};
