import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../../components/LiboBerryCss/liboBerry.css";
import LabelItem from "../../../components/Tags/LabelItem";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  InputAdornment,
  FormLabel,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import {
  postPrompts,
  editPrompts,
  postPromptsTags,
  getPromptsTags,
  deletePromptsTags,
} from "../../../Requests/Requests";
import { CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AddTagDialog from "../../DocumentsPage/components/CreateNewLabelDialog";
function BookMarkBox(props) {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [loadingPrompt, setLoadingPrompt] = useState(false);
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);

  const [searchLabels, setSearchLabels] = useState("");
  const [showLabelsBox, setShowLabelsBox] = useState(false);

  useEffect(() => {
    setText(props.isShow ? props.promptText : "");
    setTitle(props.isShow ? props.promptTitle : "");
    setShowLabelsBox(false);
    setSearchLabels("");
    setLoadingPrompt(false);
    setIsAddTagDialogOpen(false);
    if (props.isShow) {
      console.log(props.PromptTags);
      setTags([...props.PromptTags]);
    } else {
      setTags([]);
    }
  }, [props.isShow]);

  const callApiGetPrompts_tags = () => {
    getPromptsTags()
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          props.setPromptLabels(res.data);
        } else {
          props.setPromptLabels([]);
        }
      })
      .catch((err) => {
        console.log(
          "Seems like there is a problem with the server ",
          err.message
        );
      });
  };
  const handleRemovePrompsTags = (id) => {
    deletePromptsTags(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Label has been deleted successfully");
          props.setPromptLabels([
            ...props.promptLabels.filter((s) => s.id != id),
          ]);
          handleRemoveTag(id);
        }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const handleAddPromps_tags = (data) => {
    return postPromptsTags(data);
  };
  const handleCreateTag = (id, tagName, tagColor) => {
    handleAddTagClick(id);
    setIsAddTagDialogOpen(false);
  };
  const handleAddTagClick = (tagId) => {
    if (tags == null || tags.length == 0) {
      setTags([tagId]);
    } else if (tags != null && tags.length > 0) {
      if (tags.filter((s) => s == tagId).length == 0) {
        setTags([...tags, tagId]);
      }
    }
  };
  const handleRemoveTag = (tagId) => {
    if (tags.filter((s) => s == tagId).length > 0) {
      setTags([...tags.filter((s) => s != tagId)]);
    }
  };

  const callApiEditPrompt = () => {
    setLoadingPrompt(true);
    let labels = [
      ...tags.filter(
        (s) =>
          props.promptLabels != null &&
          props.promptLabels.filter((t) => t.id == s).length > 0
      ),
    ];
    editPrompts({
      id: props.promptId,
      prompt_text: text.trim(),
      prompt_title: title.trim(),
      tags: labels,
    })
      .then((res) => {
        setLoadingPrompt(false);
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Prompt has been updated successfully");
          props.Save({
            bookmarkId: props.promptId,
            bookmarkTitle: title.trim(),
            bookmarkTags: labels,
            bookmarkText: text.trim(),
          });
        }
      })
      .catch((err) => {
        setLoadingPrompt(false);
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const callApiAddPrompt = () => {
    setLoadingPrompt(true);
    postPrompts({
      prompt_text: text.trim(),
      prompt_title: title.trim(),
      tags: tags,
    })
      .then((res) => {
        setLoadingPrompt(false);
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Prompt has been saved successfully");
          props.Save({
            bookmarkId: res.data.id,
            bookmarkTitle: title.trim(),
            bookmarkTags: tags,
            bookmarkText: text.trim(),
          });
        }
      })
      .catch((err) => {
        setLoadingPrompt(false);
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const checkValidation = () => {
    let hasError = false;
    if (loadingPrompt) {
      return false;
    } else {
      if (title == null || title.trim() == "") {
        toast.error("Please input title");
        hasError = true;
      }
      if (text == null || text.trim() == "") {
        hasError = true;
        toast.error("Please input prompt");
      }
    }
    return !hasError;
  };
  return (
    <>
      {props.isShow && (
        <Dialog
          classes={{ paper: { background: "red" } }}
          open={props.isShow}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              color: "white",
              backgroundColor: "#eb3449",
            }}
            id="alert-dialog-title"
          >
            {"Bookmark"}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              Width: "320px",
            }}
          >
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              id="txtPromptTitle"
              label="Title"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              id="txtPromptText"
              label="Prompt"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
            <br />
            <FormControl
              component="fieldset"
              style={{
                padding: "5px 0px 5px 8px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "5px",
              }}
            >
              <FormLabel
                component="legend"
                style={{
                  fontSize: "0.75em",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Labels
              </FormLabel>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {tags &&
                  tags.length > 0 &&
                  tags.map((tag, index) => {
                    if (
                      props.promptLabels != null &&
                      props.promptLabels.length > 0
                    ) {
                      let labels = [
                        ...props.promptLabels.filter((s) => s.id == tag),
                      ];
                      if (labels.length > 0) {
                        let label = labels.pop();
                        return (
                          <LabelItem
                            curserIsPointer={false}
                            hasCount={false}
                            key={index + " " + tag}
                            name={label.tag_name}
                            color={label.color}
                            hasDeleteIcon={true}
                            onDeleteClick={() => {
                              handleRemoveTag(tag);
                            }}
                          />
                        );
                      }
                    }
                  })}
              </div>
              <div>
                <TextField
                  // inputRef={txtAsk}
                  // InputLabelProps={{ shrink: true }}
                  placeholder="Search Labels"
                  // variant="outlined"
                  size="small"
                  disabled={false}
                  style={{ width: "100%" }}
                  InputProps={{
                    className: "inputFont inputFontSize0_7em",
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          title={"Create new label"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={(e) => {
                            setIsAddTagDialogOpen(true);
                          }}
                        >
                          <AddIcon
                            style={{
                              backgroundColor: "#51cc84",
                              borderRadius: "100%",
                              padding: "3px",
                              color: "white",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  value={searchLabels}
                  onFocus={() => setShowLabelsBox(true)}
                  // onBlur={() => setShowLabelsBox(false)}
                  onClick={() => {
                    setShowLabelsBox(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.which == 27) {
                      //escape
                      setShowLabelsBox(false);
                      return;
                    }
                    setShowLabelsBox(true);
                    if (e.which == 13) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.which == 13) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setSearchLabels(e.target.value);
                  }}
                ></TextField>
              </div>
              <div
                className={"liboScroll"}
                style={{ height: "60px", overflowX: "auto" }}
              >
                {showLabelsBox &&
                  props.promptLabels &&
                  props.promptLabels.length > 0 &&
                  props.promptLabels
                    .filter((s) =>
                      s.tag_name
                        .toLowerCase()
                        .trim()
                        .includes(searchLabels.trim().toLowerCase())
                    )
                    .map((tag, index) => (
                      <LabelItem
                        isSelect={tags.filter((s) => s == tag.id).length > 0}
                        handleFilterSelectLabel={() => {
                          if (tags.filter((s) => s == tag.id).length > 0) {
                            handleRemoveTag(tag.id);
                          } else {
                            handleAddTagClick(tag.id);
                          }
                        }}
                        hasDeleteIcon={true}
                        onDeleteClick={() => {
                          handleRemovePrompsTags(tag.id);
                        }}
                        key={tag.id}
                        hasCount={false}
                        name={tag.tag_name}
                        color={tag.color}
                        type={"list"}
                      />
                    ))}
              </div>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 15px",
              }}
            >
              <Button onClick={props.Close} className={"liboBtnCancel"}>
                Cancel
              </Button>

              <Button
                onClick={() => {
                  if (checkValidation()) {
                    if (props.promptId) {
                      callApiEditPrompt();
                    } else {
                      callApiAddPrompt();
                    }
                  }
                }}
                className={loadingPrompt ? "liboBtnDisable" : "liboBtnApprove"}
                autoFocus
              >
                {<>{props.promptId == null ? "Save" : "Update"}</>}
                {loadingPrompt && (
                  <>
                    &nbsp;&nbsp;
                    <CircularProgress
                      style={{
                        height: "22px",
                        width: "22px",
                      }}
                    ></CircularProgress>
                  </>
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      <AddTagDialog
        key={"Crate New Label"}
        title="Create New Label"
        open={isAddTagDialogOpen}
        onClose={() => {
          setIsAddTagDialogOpen(false);
        }}
        onCreateTag={handleCreateTag}
        onrefreshtag={callApiGetPrompts_tags}
        apiCallSetLabel={handleAddPromps_tags}
        useTagNameProperty={true}
        tags={tags} // pass the tags state
        // onAddTag={handleAddTag} // pass the handleAddTag function
      />
    </>
  );
}
BookMarkBox.propTypes = {
  isShow: PropTypes.bool,
  Close: PropTypes.func,
  promptText: PropTypes.string,
  promptId: PropTypes.number,
  promptTitle: PropTypes.string,
  PromptTags: PropTypes.array,
  Save: PropTypes.func,
  promptLabels: PropTypes.array,
  setPromptLabels: PropTypes.func,
};

BookMarkBox.defaultProps = {
  isShow: false,
  Close: () => {},
  promptText: "",
  promptId: null,
  promptTitle: "",
  PromptTags: [],
  Save: () => {},
  promptLabels: [],
  setPromptLabels: () => {},
};

export default BookMarkBox;
