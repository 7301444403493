import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MathJax from "react-mathjax2";
import {
  Button,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { toast } from "react-toastify";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import InputAdornment from "@material-ui/core/InputAdornment";
import classes from "../Toolbar.module.css";
import "../../../LiboBerryCss/liboBerry.css";
import ClearIcon from "../../../Icons/clearIcon.js";
import { generateFormula } from "../../../../Requests/Requests.js";

const FormulaDialog = (props) => {
  const [latexFormulaValueG, setLatexFormulaValueG] = useState("");
  const [loading, setLoading] = useState(false);
  const [latexFormulaValue, setLatexFormulaValue] = useState(
    props.latexFormula
  );
  const [latexLabelValue, setLatexLabelValue] = useState(props.label);
  const [latexInline, setLatexInline] = useState(props.inline);
  useEffect(() => {
    setLatexLabelValue("");
    setLatexInline("");

    setLatexFormulaValue("");
    setLatexFormulaValueG("");
  }, []);

  useEffect(() => {
    setLatexLabelValue(props.label);
  }, [props.label]);

  useEffect(() => {
    setLatexInline(props.inline);
  }, [props.inline]);

  useEffect(() => {
    setLatexFormulaValue(props.latexFormula);
  }, [props.latexFormula]);

  const generateFormulaHandler = (projectId, formulaName) => {
    setLoading(true);
    generateFormula(projectId, { formula: formulaName })
      .then((res) => {
        setLoading(false);
        setLatexFormulaValueG("");
        if (res.status >= 200 && res.status <= 299) {
          if (latexFormulaValue != null && latexFormulaValue.trim() != "") {
            setLatexFormulaValue(
              (prevValue) => `${prevValue}\n\\\\ ${res.data.latex_formula}`
            );
          } else {
            setLatexFormulaValue(
              (prevValue) => `${prevValue} ${res.data.latex_formula}`
            );
          }
          if (!latexLabelValue || latexLabelValue === latexFormulaValueG) {
            setLatexLabelValue(formulaName);
          }
        }
      })
      .catch((err) => {
        setLoading(false);

        if (err.response && err.response.data && err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An error occurred. Please try again.");
          console.log(err, err.response);
        }
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      generateFormulaHandler(props.projectId, latexFormulaValueG);
    }
  };
  return (
    <Dialog
      key={props.key || props.title}
      classes={{ paper: { background: "red" } }}
      open={props.open}
      onClose={props.handleCloseFormulaModel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ color: "white", backgroundColor: "#eb3449" }}
        id="alert-dialog-title"
      >
        {"LaTeX Formula"}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className={classes.GenerateBox}>
          <TextField
            id="GntLatextFormula"
            label="Generate Formula"
            //size="small"
            variant="outlined"
            className={classes.Generate}
            onKeyDown={handleKeyDown}
            value={latexFormulaValueG}
            placeholder="Formula name (i.e Fibonacci), or Explain the formula"
            onChange={(e) => setLatexFormulaValueG(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              className: "inputFont",
              endAdornment: (
                <InputAdornment style={{ paddingRight: "20px" }} position="end">
                  <IconButton
                    style={{ position: "absolute", right: "0px" }}
                    onClick={() => {
                      if (!loading) {
                        generateFormulaHandler(
                          props.projectId,
                          latexFormulaValueG
                        );
                      }
                    }}
                    disabled={loading}
                  >
                    {!loading ? (
                      <ArrowUpwardIcon
                        style={{
                          backgroundColor: "#51cc84",
                          borderRadius: "100%",
                          padding: "3px",
                          color: "white",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      <CircularProgress
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <div
            title="Clear formula"
            onClick={() => {
              setLatexFormulaValue("");
            }}
            className={classes.eraseFormulaBox}
          >
            <ClearIcon color="#7b6bc9" width="15px" />
          </div>
          <TextField
            InputProps={{
              className: "inputFont",
            }}
            id="txtLatextFormula"
            label="LaTeX Formula "
            variant="outlined"
            style={{ marginTop: "30px", width: "100%" }}
            disabled={false}
            minRows={3}
            maxRows={5}
            multiline
            value={latexFormulaValue}
            onChange={(e) => setLatexFormulaValue(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
            alignItems: "center",
          }}
        >
          <TextField
            InputProps={{
              className: "inputFont",
            }}
            id="txtLatextLabel"
            label="LaTeX Label"
            variant="outlined"
            value={latexLabelValue}
            onChange={(e) => setLatexLabelValue(e.target.value)}
            className={classes.textField}
          />
          <div style={{ marginLeft: "15px" }}>
            <FormControlLabel
              label="Inline"
              control={
                <Checkbox
                  id="chkLatexInline"
                  checked={latexInline}
                  onChange={(val) => {
                    setLatexInline(!latexInline);
                  }}
                />
              }
            />
          </div>
        </div>

        <br />
        <div id="HtmlFormulaDialogBox" style={{ minHeight: "100px" }}>
          <MathJax.Context
            config={{
              "html-css": {
                fonts: ["sana-math"],
                fontSize: "12px",
                scale: 400,
              },
            }}
            input="tex"
          >
            <div>
              <MathJax.Node>
                {latexFormulaValue.trim().replaceAll("&", "")}
              </MathJax.Node>
            </div>
          </MathJax.Context>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCloseFormulaModel}
          className={"liboBtnCancel"}
        >
          Cancel
        </Button>
        {props.showRemove && (
          <Button
            onClick={() => {
              props.handleClickRemove();
            }}
            className={"liboBtnRemove"}
          >
            Remove
          </Button>
        )}
        <Button
          onClick={() => {
            if (
              !(latexFormulaValue == null || latexFormulaValue.trim() == "")
            ) {
              let html = document
                .getElementById("HtmlFormulaDialogBox")
                .getElementsByClassName("mjx-math");
              props.handleClickAccept(
                latexFormulaValue,
                latexLabelValue,
                latexInline,
                html
              );
            }
          }}
          autoFocus
          className={
            latexFormulaValue == null || latexFormulaValue.trim() == ""
              ? "liboBtnDisable"
              : "liboBtnApprove"
          }
        >
          Insert
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormulaDialog;
FormulaDialog.propTypes = {
  title: PropTypes.string,
  key: PropTypes.object,
  open: PropTypes.bool,
  handleCloseFormulaModel: PropTypes.func,
  projectId: PropTypes.number,
  handleClickRemove: PropTypes.func,
  handleClickAccept: PropTypes.func,
  showRemove: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  latexFormula: PropTypes.string,
};

FormulaDialog.defaultProps = {
  title: "FormulaDialog",
  key: null,
  open: false,
  handleCloseFormulaModel: () => {},
  projectId: 0,
  handleClickRemove: () => {},
  handleClickAccept: () => {},
  showRemove: false,
  inline: false,
  label: "",
  latexFormula: "",
};
