export function handleShortCutKey(
  e,
  shortCutKey = null,
  callBackfunction = null
) {
  let result = "";
  let controlKey = findClientOs() == "MacOS" ? e.metaKey : e.ctrlKey;
  // e.preventDefault();
  //ctrl + S  : Save
  if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "Save") &&
    e.which == 83
  ) {
    if (callBackfunction) callBackfunction();
    result = "save";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "Bold") &&
    e.which == 66
  ) {
    //ctrl+b  : Bold
    if (callBackfunction) callBackfunction();
    result = "bold";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "Italic") &&
    e.which == 73
  ) {
    //ctrl+i  : Italic
    if (callBackfunction) callBackfunction();
    result = "italic";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "underline") &&
    e.which == 76
  ) {
    //ctrl+l  : underline
    if (callBackfunction) callBackfunction();
    result = "underline";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "strikeThrough") &&
    e.which == 68
  ) {
    //ctrl+d  : strikeThrough
    if (callBackfunction) callBackfunction();
    result = "strikeThrough";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "superscript") &&
    e.shiftKey &&
    e.which == 187
  ) {
    //ctrl+shift+'+'  : superscript
    if (callBackfunction) callBackfunction();
    result = "superscript";
  } else if (
    controlKey &&
    (shortCutKey == null || shortCutKey == "subscript") &&
    e.which == 187
  ) {
    //ctrl+'='  : subscript
    if (callBackfunction) callBackfunction();
    result = "subscript";
  } else if (
    e.altKey &&
    (shortCutKey == null || shortCutKey == "InsertFormula") &&
    e.which == 187
  ) {
    //Alt+'='  : Insert Formula
    if (callBackfunction) callBackfunction();
    result = "InsertFormula";
  } else if (
    controlKey &&
    e.shiftKey &&
    (shortCutKey == null || shortCutKey == "Citations") &&
    e.which == 191
  ) {
    // Ctrl + shift + / : Check Citations
    if (callBackfunction) callBackfunction();
    result = "Citations";
  } else if (
    e.altKey &&
    (shortCutKey == null || shortCutKey == "AI") &&
    e.which == 65
  ) {
    //Alt+'A'  : Call AI
    if (callBackfunction) callBackfunction();
    result = "AI";
  } else if ((shortCutKey == null || shortCutKey == "ESC") && e.which == 27) {
    // Ctrl + shift + / : Check Citations
    if (callBackfunction) callBackfunction();
    result = "ESC";
  }

  return result;
}

export function getShortCut(shortCutKey) {
  let isMacOs = findClientOs() == "MacOS";
  let CtrlKeyName = isMacOs ? "⌘" : "Ctrl";
  let AltKeyName = isMacOs ? "⌥" : "Alt";
  if (shortCutKey == "Save") {
    //Ctrl + S
    return CtrlKeyName + " + S";
  } else if (shortCutKey == "Bold") {
    //ctrl+b  : Bold
    return CtrlKeyName + " + B";
  } else if (shortCutKey == "Italic") {
    //ctrl+i  : Italic
    return CtrlKeyName + " + I";
  } else if (shortCutKey == "underline") {
    //ctrl+u  : underline
    return CtrlKeyName + " + L";
  } else if (shortCutKey == "strikeThrough") {
    //ctrl+d  : strikeThrough
    return CtrlKeyName + " + D";
  } else if (shortCutKey == "superscript") {
    //ctrl+shift+'+'  : superscript
    return CtrlKeyName + " + Shift + '+'";
  } else if (shortCutKey == "subscript") {
    //ctrl+'='  : subscript
    return CtrlKeyName + " + '='";
  } else if (shortCutKey == "InsertFormula") {
    //Alt+'='  : Insert Formula
    return AltKeyName + " + '='";
  } else if (shortCutKey == "Citations") {
    // Ctrl + / : Check Citations
    return CtrlKeyName + "+ Shift + /";
  } else if (shortCutKey == "AI") {
    // ALT + A : Check Citations
    return AltKeyName + "+ A ";
  }
  return "";
}

export const findClientOs = () => {
  let os = navigator.userAgent;
  let finalOs = "";
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  } else if (os.search("Mac") !== -1) {
    finalOs = "MacOS";
  } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
    finalOs = "UNIX";
  } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
    finalOs = "Linux";
  }

  return finalOs;
};

export const OsControlKey = (controlKey) => {
  let ctKey = controlKey;
  if (findClientOs() == "MacOS") {
    if (controlKey == "Ctrl") {
      controlKey = "⌘";
    } else if (controlKey == "Alt") {
      controlKey = "⌥";
    }
  }
  return controlKey;
};
