import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  IconButton,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "./styles.module.css";
import { validateEmail } from "../../../Functions/liboFunction";
import "../../../components/LiboBerryCss/liboBerry.css";
import classess from "./ShareProjectDialog.module.css";
import { projectShare, projectShareDelete } from "../../../Requests/Requests";
import { toast } from "react-toastify";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import { CircularProgress } from "@material-ui/core";

function ShareProjectDialog({
  open,
  onClose,
  onRefreshShareList,
  project_Id,
  shareList,
  refreshProject,
}) {
  const [email, setEmail] = useState("");
  const [statusShare, setStatusShare] = useState("");
  const [emailList, setEmailList] = useState([]);
  const isValidEmail = () => {
    if (email.trim() == "" && emailList.length > 0) {
      return true;
    } else if (email.trim() != "" && validateEmail(email)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setEmailList([]);
    setEmail("");
    if (shareList == null) {
      shareList = [];
    }
  }, [open]);
  const callApiProjectShare = (projectId, emailsToAdd) => {
    let data = {
      emails: emailsToAdd,
    };
    setStatusShare("loading");
    projectShare(projectId, data)
      .then((res) => {
        setStatusShare("");
        onRefreshShareList();
        if (res.status >= 200 || res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
            refreshProject();
          }
        } else {
          toast.error("Shared failed");
        }
      })
      .catch((err) => {
        setStatusShare("");
        onRefreshShareList();

        if (err.response.data.message) {
          toast.success(err.response.data.message);
          refreshProject();
        }
        if (err.response.data.errors) {
          for (let error of err.response.data.errors) {
            toast.error(error);
          }
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          console.log(err, err.response);
        }
      });
  };

  const handleAddEmail = () => {
    if (isValidEmail()) {
      let emailsToAdd = [...emailList];
      if (email.trim() != "" && validateEmail(email)) {
        if (
          !(
            emailList.includes(email) &&
            shareList.filter((s) => s.email == email).length > 0
          )
        ) {
          emailsToAdd = [...emailList, email];
        }
      }
      setEmailList([]);
      setEmail("");
      callApiProjectShare(project_Id, emailsToAdd);
    }
  };

  const handleDeleteEmail = (email) => {
    projectShareDelete(project_Id, { email: email })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          toast.success(res.data.message);
          refreshProject();
        }
        onRefreshShareList();
      })
      .catch((err) => {
        onRefreshShareList();
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          console.log(err, err.response);
        }
      });
  };

  const handleClose = () => {
    onClose();
  };
  const handleDelete = (item, index) => {
    setEmailList([...emailList.filter((s) => s != item)]);
  };
  const AddToEmailList = (value) => {
    if (validateEmail(value)) {
      if (
        emailList.includes(value) ||
        shareList.filter((s) => s.email == value).length > 0
      ) {
        toast.error("email is Exist");
      } else {
        setEmail("");
        setEmailList([...emailList, value]);
      }
    } else {
      toast.error("please Enter Valid Email");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="share-project-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={"dialog-title-panel "}>
        <Typography variant="h6">Share Project</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className={styles.subtitleText}>
          Share with your collaborators
        </Typography>
        <div>
          {emailList.map((item, index) => (
            <Chip
              title={item}
              className={styles.textEllipsis}
              size="small"
              onDelete={() => handleDelete(item, index)}
              label={item}
              style={{ backgroundColor: "#00800042" }}
            />
          ))}
        </div>
        <div className={styles.shareInputBox}>
          <div className={styles.shareInputText}>
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              autoFocus
              margin="dense"
              id="email"
              placeholder="Separate your email with enter key"
              type="email"
              onKeyUp={(e) => {
                let value = e.target.value.trim();
                if (e.keyCode == 13 && value != "") {
                  AddToEmailList(value);
                }
              }}
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputLabelProps={{ className: styles.textField }}
              inputProps={{ className: styles.input }}
              className={styles.textField2}
            />
          </div>
          <div className={styles.sharebtn}>
            <Button
              variant="outlined"
              onClick={() => {
                if (isValidEmail() && statusShare != "loading") {
                  handleAddEmail();
                }
              }}
              fullWidth
              className={`${styles.addButton} ${
                !isValidEmail() || statusShare == "loading"
                  ? "liboBtnDisable"
                  : "liboBtnApprove"
              }`}
              disabled={!isValidEmail()} // Disable button if email is invalid
            >
              {statusShare == "loading" ? (
                <>
                  &nbsp;
                  <CircularProgress
                    style={{
                      height: "22px",
                      width: "22px",
                    }}
                  />
                </>
              ) : (
                <>Share</>
              )}
            </Button>
          </div>
        </div>
        <div className={styles.emailListWrapper}>
          {shareList.length > 0 && (
            <div className={`${styles.container} liboScroll`}>
              <Table aria-label="caption table" style={{ width: "100%" }}>
                <TableBody>
                  {shareList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={styles.tableCell}
                        style={{ paddingRight: "0px !importent" }}
                        scope="row"
                      >
                        <div className={styles.sharePanelBox}>
                          <div className={classess.shareListPanelBox}>
                            <div
                              className={
                                classess.SharePersonIcon +
                                " " +
                                (item.profile_image != null
                                  ? classess.shareProfileIcon
                                  : "")
                              }
                              style={{
                                backgroundImage:
                                  item.profile_image != null
                                    ? "url('" + item.profile_image + "')"
                                    : "none",
                              }}
                            >
                              {item.profile_image == null ? (
                                <PersonIcon />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div>
                              <div
                                title={item.first_name + " " + item.last_name}
                                className={
                                  classess.shareListFullName +
                                  " " +
                                  styles.fullnameTextEllipsis
                                }
                              >
                                {item.first_name}&nbsp;{item.last_name}
                              </div>
                              <div
                                title={item.email}
                                className={styles.emailText}
                              >
                                {item.email}
                              </div>
                            </div>
                          </div>
                          <div className={styles.shareListActionButtonsBox}>
                            {item.verified ? (
                              <div
                                title={"verified"}
                                className={styles.doneIcon}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "14px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                title={"Awaiting confirmation"}
                                className={styles.PendingIcon}
                              >
                                <HourglassEmptyIcon
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </div>
                            )}

                            <IconButton
                              title={"Remove Share"}
                              onClick={() => handleDeleteEmail(item.email)}
                              className={styles.deleteIcon}
                            >
                              <DeleteIcon style={{ width: "24px" }} />
                            </IconButton>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {shareList.length === 0 && (
            <Typography variant="subtitle1" className={styles.emptyCell}>
              Share list is empty...
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              onClick={handleClose}
              className={`${styles.closeButton} liboBtnCancel`}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ShareProjectDialog;
ShareProjectDialog.propTypes = {
  refreshProject: PropTypes.func,
};
ShareProjectDialog.defaultProps = {
  shareList: [],
  refreshProject: () => {},
};
