import React from "react";
import "../LiboberryAIAssistents.css";
import EnterIcon from "../../Icons/EnterIcon.js";
import PropTypes from "prop-types";

const AIItem = (props) => {
  return (
    <div
      className={"aISuggestion_Item" + (props.disabled ? "_disabled" : "")}
      title={props.tooltip}
      style={{ ...props.style }}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      <div className={"aITextItem"}>{props.title}</div>
      <div className={"aiSuggestionEnter_Icon"}>
        <EnterIcon />
      </div>
    </div>
  );
};
export default AIItem;
AIItem.propTypes = {
  title: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.func,
  tooltip: PropTypes.string,
};
AIItem.defaultProps = {
  title: <></>,
  onClick: () => {},
  disabled: false,
  style: null,
  tooltip: "",
};
